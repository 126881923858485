import Phaser from 'phaser';
import Drag from 'phaser3-rex-plugins/plugins/drag.js';

export default class Scene1e extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene1e"});
	}


    create()
    {

			this.draggingnow="";
      this.add.image(960, 540, 'boxcu');

			if(state.jbopen) {
				this.add.image(960, 540, 'jbopen');
			} else {
			this.slidernw=this.add.image(908, 248,'objectpack', 'slidernw');
			this.centreb=this.add.image(941, 279, 'objectpack', 'centreb');
			this.slidere=this.add.image(1071, 295, 'objectpack', 'slidere');
			this.centrea=this.add.image(941, 279, 'objectpack', 'centrea');
			this.slidersw=this.add.image(903, 345, 'objectpack', 'slidersw');
      //Slider NW
			this.slidernw.drag=new Drag(this.slidernw, {});
			this.slidernw.drag.setAxisRotation(310.5 * Math.PI / 180);
			this.slidernw.drag.setAxisMode(2);

			this.slidernw.on('drag', function(e) {
				this.draggingnow="1";
console.log(this.slidernw.x);
				if(this.slidernw.x<=842) {
					this.slidernw.x=842;
							if(state.jbslider1open==false) {
					updateState("jbslider1open", true);
				}
			}  else {
				if(state.jbslider1open) {
						updateState("jbslider1open", false);
					}
				}
				if(this.slidernw.x>908) {
					this.slidernw.x=908;
				}
				if(this.slidernw.y<186) {
					this.slidernw.y=186;
				} else if(this.slidernw.y>248) {
					this.slidernw.y=248;
				}
				updateState("jbslider1x", this.slidernw.x);
				updateState("jbslider1y", this.slidernw.y);


		}, this);


		this.slidernw.on('dragend', function(e) {
			this.draggingnow="";
			this.slidernw.x=908;
			this.slidernw.y=248;
			updateState("jbslider1x", 908);
			updateState("jbslider1y", 248);
				if(state.jbslider1open) {
				updateState("jbslider1open", false);
			}
		}, this);

		// SLIDER E

		this.slidere.drag=new Drag(this.slidere, {});
		this.slidere.drag.setAxisRotation(90 * Math.PI / 180);
		this.slidere.drag.setAxisMode(2);

		this.slidere.on('drag', function(e) {
this.draggingnow="2";
			if(this.slidere.x>=1236) {
				this.slidere.x=1236;
						if(state.jbslider2open==false) {
				updateState("jbslider2open", true);
			}

			} else {
				if(state.jbslider2open) {
					updateState("jbslider2open", false);
				}
			}

			if(this.slidere.x<1071) {
					this.slidere.x=1071;
				}
				updateState("jbslider2x", this.slidere.x);
				updateState("jbslider2y", this.slidere.y);
	}, this);
	this.slidere.on('dragend', function(e) {
		this.draggingnow="";
		this.slidere.x=1071;
		this.slidere.y=295;
		updateState("jbslider2x", 1071);
		updateState("jbslider2y", 295);
			if(state.jbslider2open) {
		updateState("jbslider2open", false);
	}


	}, this);

	//Slider SW
	this.slidersw.drag=new Drag(this.slidersw, {});
	this.slidersw.drag.setAxisRotation(230 * Math.PI / 180);
	this.slidersw.drag.setAxisMode(2);

	this.slidersw.on('drag', function(e) {
this.draggingnow="3";
		if(this.slidersw.x<=810) {
			this.slidersw.x=810;
			if(state.jbslider3open==false) {
			updateState("jbslider3open", true);
		}

		} else {
			if(state.jbslider3open==true) {
			updateState("jbslider3open", false);
		}
		}

		 if(this.slidersw.x>903) {
			this.slidersw.x=903;
		}

		if(this.slidersw.y>424) {
			this.slidersw.y=424;
		} else if(this.slidersw.y<345) {
			this.slidersw.y=345;
		}


		updateState("jbslider3x", this.slidersw.x);
		updateState("jbslider3y", this.slidersw.y);
}, this);
this.slidersw.on('dragend', function(e) {
	this.draggingnow="";
	this.slidersw.x=903;
	this.slidersw.y=345;
	updateState("jbslider3x",903);
	updateState("jbslider3y", 345);
		if(state.jbslider3open) {
	updateState("jbslider3open", false);
}

}, this);
}

this.jbopen=this.add.image(960, 540, "jbopen").setAlpha(0);
this.jewelopen=this.add.video(960, 540, 'jewelopen').setAlpha(0);
if(!state.jbopen) {
this.jbkey=this.add.image(951, 680, "jbkey").setAlpha(0);
this.pickkey=this.add.image(954,716, 'doorpack',"pickkey").setInteractive({useHandCursor: true}).setAlpha(0);
this.pickkey.on('pointerdown', function(){
	this.game.inventoryAdd(this, "key");
	 updateState("pickedkey", true);
}, this);
} else {
	this.jbkey=this.add.image(951, 680, "jbkey").setAlpha(1);
	this.pickkey=this.add.image(954,716, 'doorpack', "pickkey").setInteractive({useHandCursor: true}).setAlpha(0.01);
	this.pickkey.on('pointerdown', function(){
		this.game.inventoryAdd(this, "key");
		 updateState("pickedkey", true);
	}, this);
}

this.boxempty=this.add.image(960, 540, "jbopen").setAlpha(0);
this.tofeb10=this.add.image(327, 451, 'doorpack', "tofeb10").setInteractive({useHandCursor: true}).setAlpha(0.01);
this.tofeb10.on('pointerdown', function(){
	 this.scene.start("Scene1j");
}, this);
this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
this.backbutton.on('pointerdown', function(){
	 this.scene.start("Scene1c");
}, this);

this.jbstateoncreate=state.jbopen;

			newState=true;
    }

		update() {
			if(newState) {
				if(this.draggingnow!="1") {
				var tween = this.tweens.add({
						targets: this.slidernw,
						x: state.jbslider1x,
						y: state.jbslider1y,
						ease: 'Power2',
						duration: 300
				});
}
				if(this.draggingnow!="2") {
				var tween = this.tweens.add({
						targets: this.slidere,
						x: state.jbslider2x,
						y: state.jbslider2y,
						ease: 'Power2',
						duration: 300
				});
			}
			if(this.draggingnow!="3") {
				var tween = this.tweens.add({
						targets: this.slidersw,
						x: state.jbslider3x,
						y: state.jbslider3y,
						ease: 'Power2',
						duration: 300
				});
			}

			console.log("slider1: "+state.jbslider1open+" slider2: "+state.jbslider2open+" / slider3: "+state.jbslider3open);

			if((state.jbslider1open && state.jbslider2open && state.jbslider3open) && !state.jbopen) {
				updateState("jbopen", true);
				updateState("milestone", "openwardrobe");
				console.log("BOX OPEN!");
			}
	console.log("jbopen:"+state.jbopen+" jbstateoncreate:"+this.jbstateoncreate);
			if(state.jbopen && !this.jbstateoncreate) {
				this.slidere.drag.setEnable(false);
				this.slidernw.drag.setEnable(false);
				this.slidersw.drag.setEnable(false);
			  this.jewelopen.setAlpha(1).play();
				this.jewelopen.play();
				this.jewelopen.on("complete", function() {

				this.pickkey.setAlpha(0.01);

				this.jbstateoncreate=true;
			}, this);
		}


			if(state.jbopen && this.jbstateoncreate) {

				this.pickkey.setAlpha(0.01);
			}

				if(state.pickedkey) {
					this.boxempty.setAlpha(1);
				}

				newState=false;
			}

		}


}
