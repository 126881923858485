import Phaser from 'phaser'

export default class Scene3f extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3f"});
	}

    create()
    {
			this.add.image(960, 540, 'cc');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene3");
			}, this);
			this.topoem=this.add.image(788, 857, 'doorpack','topoem').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.topoem.on('pointerdown', function(){
				 this.scene.start("Scene3j");
			}, this);
			this.tomap=this.add.image(1478, 800, 'doorpack','tomap').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.tomap.on('pointerdown', function(){
				 this.scene.start("Scene3k");
			}, this);
			newState=true;
    }

		update() {
			if(newState) {

				newState=false;
			}

		}
}
