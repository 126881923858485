// Scene 3 - Lightswitch

import Phaser from 'phaser'

export default class Scene3a extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3a"});
	}

    create()
    {
			this.add.image(960, 540, 'lsbase');
			var yRows=[447,492,537,584,631,677];
			for (var i = 0; i < 6; i++) {
			this["l"+i]=this.add.image(945, yRows[i],'objectpack', "l"+i).setInteractive({useHandCursor: true}).setAlpha(0);
		}
		this.lightson=state.lightson;
		this.bottom=this.add.image(1147, 650,'objectpack', "bottom").setInteractive({useHandCursor:true});
		this.bottom.on('pointerdown', function() {
			this.buttonPress("B");
			this.bottom.setAlpha(0.01);
			var timer = this.time.addEvent({
				delay: 300,                // ms
				callback: function(){this.bottom.setAlpha(1)},
				callbackScope: this,
				loop: false
			});
		}, this);

		this.top=this.add.image(1147, 471, 'objectpack',"top").setInteractive({useHandCursor:true});

		this.top.on('pointerdown', function() {
			this.buttonPress("T");
			this.top.setAlpha(0.01);
			var timer = this.time.addEvent({
				delay: 300,                // ms
				callback: function(){this.top.setAlpha(1)},
				callbackScope: this,
				loop: false
			});
		}, this);

		this.left=this.add.image(1030, 561, 'objectpack',"left").setInteractive({useHandCursor:true});
		this.left.on('pointerdown', function() {
			this.buttonPress("L");
			this.left.setAlpha(0.01);
			var timer = this.time.addEvent({
				delay: 300,                // ms
				callback: function(){this.left.setAlpha(1)},
				callbackScope: this,
				loop: false
			});
		}, this);

		this.right=this.add.image(1263, 561,'objectpack', "right").setInteractive({useHandCursor:true});
		this.right.on('pointerdown', function() {
			this.right.setAlpha(0.01);
			this.buttonPress("R");
			var timer = this.time.addEvent({
				delay: 300,                // ms
				callback: function(){this.right.setAlpha(1)},
				callbackScope: this,
				loop: false
			});
		}, this);

		this.buttonup=this.add.image(717,515, 'objectpack','buttonup').setInteractive({useHandCursor: true});
		this.buttonup.on('pointerdown', function() {

		if(!state.lightson) {
		updateState("lightsequencestarted", Date.now());
	}

		}, this);

			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});

			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene3");
			}, this);

			newState=true;
    }

		buttonPress(button){
				if(!state.lightson) {
				updateState("basementlights", (state.basementlights+1));
				var newLightSequence=state.lightsequence;
				newLightSequence.push(button);
				updateState("lightsequence", newLightSequence);

				if(state.basementlights>5) {
					var correctCode=["holder","L","R","T","B","T","R"];
					if(JSON.stringify(newLightSequence)==JSON.stringify(correctCode)) {
						updateState("lightson", true);
						updateState("milestone", "cleancoffee");
						this.scene.start("Scene3i");
					} else {
						this.keypaderror = this.time.addEvent({
			delay: 300,                // ms
			callback: function(){
				updateState("basementlights", 0);
				updateState("lightsequence", ["holder"]);
			},
			callbackScope: this,
			loop: false
	});
				}
				}  else {
							console.log(state.lightsequence);
				}
}
		}

		update() {
			if(state.lightsequencestarted==0 || (Date.now() - state.lightsequencestarted )>7000) {
			 this.buttonup.setAlpha(1);
			}
			 else {
			this.buttonup.setAlpha(0);
		}
			if(newState) {
				for (var j = 0; j < 6; j++) {
					if(j<state.basementlights) {
					this["l"+j].setAlpha(1);
				} else {
					this["l"+j].setAlpha(0);
				}
				}




				if(state.lightson && !this.lightson) {

					this.scene.start("Scene3i");

				}

				newState=false;

			}

		}
}
