import Phaser from 'phaser'

export default class Scene1a extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene1a"});
	}

	preload() {


	}

    create()
    {
			newState=true;
			var width = this.cameras.main.width;
			var height = this.cameras.main.height;


				this.bg=this.add.image(960, 540, 'safebg');

				this.dial1 = this.add.image(730, 540, 'sdial').setAlpha(0).setInteractive({useHandCursor: true});
				this.dial2 = this.add.image(962, 540, 'sdial').setAlpha(0).setInteractive({useHandCursor: true});
				this.dial3 = this.add.image(1189, 540, 'sdial').setAlpha(0).setInteractive({useHandCursor: true});

					this.dial1.on('pointerdown', function(){
					if (state.safedial1 == 8) {
						updateState("safedial1", 1);
					} else {
						updateState("safedial1", (state.safedial1+1));
					}
					this.checkSafe();
					}, this);

					this.dial2.on('pointerdown', function(){
					if (state.safedial2 == 8) {
						updateState("safedial2", 1);
					} else {
						updateState("safedial2", (state.safedial2+1));
					}
					this.checkSafe();
					}, this);

					this.dial3.on('pointerdown', function(){
					if (state.safedial3 == 8) {
						updateState("safedial3", 1);
					} else {
						updateState("safedial3", (state.safedial3+1));
					}
					this.checkSafe();
					}, this);
					if(state.safestate>2) {
											this.safeopenrecord=this.add.image(960, 540, 'safeopenrecord').setAlpha(1);
					} else {
										this.safeopen=this.add.image(960, 540, 'safeopenrecord').setAlpha(0);
					}


					this.openvideo = this.add.video(960, 540, 'safeopenvideo').setAlpha(0);

					this.stateonopen=state.safestate;


    }

		update() {
			if(newState) {
				console.log(this.stateonopen+'/'+state.safestate);
				if(this.stateonopen<3 && state.safestate==3) {
					this.openvideo.setAlpha(1);
					this.openvideo.play();
					this.stateonopen=3;
				}

				if(state.safestate==3) {
					this.dial1.setAlpha(0);
					this.dial2.setAlpha(0);
					this.dial3.setAlpha(0);
					this.lpoverlay=this.add.image(945, 540, 'lpoverlay').setInteractive({useHandCursor: true}).setAlpha(0.01);
						this.lpoverlay.on("pointerdown", function(){
							this.game.inventoryAdd(this, "lp");
							updateState("pickedlp", true);
							updateState("safestate", 4);
							newState=true;
						}, this);

					} else if(state.safestate==4){
								this.safeopenrecord=this.add.image(960, 540, 'safeopen');
							this.tojan13 = this.add.image(966,997, 'doorpack', 'tojan13').setInteractive({useHandCursor: true}).setAlpha(0.01);
							this.tojan13.on("pointerdown", function(){
							this.scene.start("Scene1i");
							}, this);
					} else if(state.safestate==1){
					this.dial1.setAlpha(1).setRotation(((((state.safedial1-1) * Math.PI) / 180)*45)*-1);
					this.dial2.setAlpha(1).setRotation(((((state.safedial2-1) * Math.PI) / 180)*45)*-1);
					this.dial3.setAlpha(1).setRotation(((((state.safedial3-1) * Math.PI) / 180)*45)*-1);

				}
				this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
				this.backbutton.on('pointerdown', function(){
					 this.scene.start("Scene1");
				}, this);
				newState=false;
			}

		}

		checkSafe() {
			if(state.safedial1==3 && state.safedial2==2 && state.safedial3==5) {
				updateState("safestate", 3);
				updateState("milestone", "lpdrop");
			}
		}
}
