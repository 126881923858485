import Phaser from 'phaser'

export default class Scene1b extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene1b"});
	}

    create()
    {
			this.add.image(960, 540, 'scene1bbg');

			this.keypadText = this.make.text({
					x: 1695,
					y: 693,
					text: '----',
					style: {
							font: '32px monospace',
							fill: '#ffffff'
					}
			});
			this.keypadText.setOrigin(0.5, 0.5);


			this.keypadbeep=this.sound.add('keypadbeep');
			this.keypadbuzzer=this.sound.add('keypadbuzzer');
			this.keypadunlock=this.sound.add('keypadunlock');
			console.log(this);

			newState=true;
			var xRows=[1625, 1699, 1773];
			var yRows=[811,861,911,961];
			var counter=1;
			for (var i = 1; i < 5; i++) {
				for (var j=1; j<4; j++){

				if(counter==11){
					this["k"+i+j]=this.add.image(xRows[(j-1)], yRows[(i-1)],"objectpack", "k0").setInteractive({useHandCursor: true});
				this["k"+i+j].padnumber=0;
			} else if (counter==10) {
					this["k"+i+j]=this.add.image(xRows[(j-1)], yRows[(i-1)],"objectpack", "kask").setInteractive({useHandCursor: true});
					this["k"+i+j].padnumber="*";
				} else if(counter==12){
						this["k"+i+j]=this.add.image(xRows[(j-1)], yRows[(i-1)],"objectpack", "khash").setInteractive({useHandCursor: true});
					this["k"+i+j].padnumber="#";
				} else {
					this["k"+i+j]=this.add.image(xRows[(j-1)], yRows[(i-1)], "objectpack", "k"+i+j).setInteractive({useHandCursor: true});
					this["k"+i+j].padnumber=counter;


				}
					this["k"+i+j].label="k"+i+j;
					this["k"+i+j].on('pointerdown', function(e){

						this.scene.keypadbeep.play();
						if(!state.trophiesopen) {
							console.log(this);
							var correctCode=["holder",4,0,1,5];
							var newKeypad=state.keypadcode;
							newKeypad.push(this.padnumber);
							console.log(newKeypad);
							var screen="";
							state.keypadcode.forEach(function(element, index) {
								if(index>0) {
								screen+=element.toString();
							}
							});
							if(state.keypadcode.length==2){
								screen+="---";
							}
							if(state.keypadcode.length==3){
								screen+="--";
							}
							if(state.keypadcode.length==4){
								screen+="-";
							}
							this.scene.keypadText.setText(screen);
						  if(newKeypad.length==5) {

							if(JSON.stringify(newKeypad)!=JSON.stringify(correctCode)) {
								var freshKeypad=["holder"];
								this.scene.keypadbuzzer.play();
								this.scene.keypadText.setText("ERROR");
								this.keypaderror = this.scene.time.addEvent({
					delay: 300,                // ms
					callback: function(){this.scene.keypadText.setText("----")},
					callbackScope: this,
					loop: false
			});

								updateState("keypadcode", freshKeypad);
							} else {
								console.log("Open!");
								this.scene.keypadunlock.play();
								updateState("trophiesopen", "true");
								updateState("milestone", "addpipe");
							}
						} else {

							updateState("keypadcode", newKeypad);
						}
					}

						 	this.scene[this.label].setAlpha(0.01);
							var timer = this.scene.time.addEvent({
				delay: 300,                // ms
				callback: function(){this.scene[this.label].setAlpha(1)},
				callbackScope: this,
				loop: false
		});

	});


					counter=counter+1;
				}
			}
			this.caseopen=this.add.image(960, 540, 'caseopen').setAlpha(0);
			this.pipecover=this.add.image(846,779,'pipecover').setInteractive({useHandCursor: true}).setAlpha(0);
			this.pipecover.on("pointerdown", function() {
				this.game.inventoryAdd(this, "pipe");
				updateState("pickedpipe", true);
			}, this);
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene1");
			}, this);
    }

		update() {
			if(newState) {

				if(state.trophiesopen) {
					this.caseopen.setAlpha(1);
					this.pipecover.setAlpha(1);
					for (var i = 1; i < 5; i++) {
						for (var j=1; j<4; j++){
							this["k"+i+j].setAlpha(0);
						}
					}
					if(state.pickedpipe) {
						this.pipecover.setAlpha(0);
					}
				}
				newState=false;
			}

		}
}
