import Phaser from 'phaser'

export default class Gps extends Phaser.Scene
{
	constructor()
	{
		super({key: "Gps"});
	}

    create()
    {

			newState=true;
			this.gpsbg=this.add.image(960,540,"gpsbg");
			this.gps=this.add.image(960, 540, "objectpack", "gpsfull").setScale(0.75);
			this.gpsform = this.add.dom(980, 470).createFromHTML(`<div id="cssinterface" style="width: 690px;  background-color: transparent; padding: 0 30px 30px 30px">

			<h2 style="font-size: 32px; width: 100%; padding: 20px 0; font-family: monospace">Target: Rita Vasconcellos</h2>
			<input type="text" id="locationField" name="locationField" placeholder="Enter target location" style="font-size: 32px; width: 100%; padding: 20px; font-family: monospace"><br /><br />
		<br />
		 <input type="button" name="enterButton" value="Search" style=" font-size: 32px; padding: 20px; font-family: monospace"><br />
     </div>
		 <div class="terminal" id="terminal" style="display: none; width: 690px; height: 350px; background-color: black; overflow-wrap: break-word; overflow: hidden; position : relative; bottom:0;
		 						color: lightgreen;
                text-shadow: 0px 0px 3px white;
                font-family: monospace;
                font-size: 1.3em;
                padding-right: 1em;
                animation-duration: 0.5s;
                animation-name: colorthing;
                animation-iteration-count: infinite;
                animation-direction: alternate;">

								</div>
			<div id="success" style="display: none; width: 690px; height: 350px; background-color: black; overflow-wrap: break-word; overflow: hidden; position : relative; bottom:0;
 		 						color: lightgreen;
                 text-shadow: 0px 0px 3px white;
                 font-family: monospace;
                 font-size: 1.3em;
                 padding-right: 1em;
                 animation-duration: 0.5s;
                 animation-name: colorthing;
                 animation-iteration-count: infinite;
                 animation-direction: alternate;
								 text-align:center
								 ">
								 <h1 style="font-size: 64px">TARGET FOUND</h1>
								 <br/><br />
									<h2>TRACKING . . .<h2>
								 </div>

								 <div id="fail" style="display: none; width: 690px; height: 350px; background-color: black; overflow-wrap: break-word; overflow: hidden; position : relative; bottom:0;
														 color: red;
														 text-shadow: 0px 0px 3px white;
														 font-family: monospace;
														 font-size: 1.3em;
														 padding-right: 1em;
														 animation-duration: 0.5s;
														 animation-name: colorthing;
														 animation-iteration-count: infinite;
														 animation-direction: alternate;
														 text-align:center
														 ">
														<h1 style="font-size: 64px">TARGET NOT FOUND</h1>
														<br/><br />
														<h2>RECALIBRATING . . .<h2>
														</div>


								`);
								var inputLocation = document.getElementById("locationField")
								var error = document.getElementById("errorField");
								var cssinterface = document.getElementById("cssinterface");
								var terminal = document.getElementById("terminal");
								var success = document.getElementById("success");
								var fail  = document.getElementById("fail");

										this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
										this.backbutton.on('pointerdown', function(){
											 console.log("After GPS" + afterGps);
											 var uiscene=this.scene.get("Ui");
											 uiscene.gps.setAlpha(1);
											 if (afterGps=="Ending") {
												 this.scene.start("Endingnovideo");
											 } else {
												 this.scene.start(afterGps);
											 }


										}, this);
				if(state.ritafound) {
					console.log("FOUND!");
					cssinterface.style.display="none";
					success.style.display="block";
				} else {


    this.gpsform.addListener('click');

	cssinterface.style.display="block";


		this.gpsform.on('click', function (event) {

				if (event.target.name === 'enterButton')
				{




						//  Have they entered anything?
						if (inputLocation.value !== '')
						{
							cssinterface.style.display="none";
							terminal.style.display="block";
							terminal.innerText = "";
							var i = 0;                  //  set your counter to 1
							var counter = 0;
							myLoop(inputLocation.value, i, counter);

						}
						else
						{
				}
				}

		}, this);


function myLoop(value, i, counter) {
	var code = `Searching maps for GPS location . . . .
							OK.
							Check traffic . . . . . . . . . . . .
							Choose radio station . . . . . . . . . . . .
							No not that one, I hate that one. . . . .
							Okay well which one then? Not that Classical rubbish
							It's my car I choose the music. . . . . . . . .
							Contacting Roadside assistance . . . . . . . . . . .
							Recalculating. . . . . . . . . . . . . . . . .`;
   //  create a loop function
  setTimeout(function() {   //  call a 3s setTimeout when the loop is called
		terminal.innerText += code.slice(i, i+5);
		i += 5;
		if (i > code.length) {i=0;}  //  your code here
		counter++;                    //  increment the counter
    if (counter < 140) {           //  if the counter < 10, call the loop function
      myLoop(value, i, counter);             //  ..  again which will trigger another
    } else {
			endLoop(value);
		}                       //  ..  setTimeout()
  }, 5);
}

function endLoop(value) {
	value=value.trim().toLowerCase();
	var allowedEntries = [
"fort william",
"ort william",
"frt william",
"fot william",
"for william",
"fort illiam",
"fort wlliam",
"fort wiliam",
"fort wiliam",
"fort willam",
"fort willim",
"fort willia",
"ffort william",
"foort william",
"forrt william",
"fortt william",
"fort wwilliam",
"fort wiilliam",
"fort willliam",
"fort willliam",
"fort williiam",
"fort williaam",
"fort williamm",
"ofrt william",
"frot william",
"fotr william",
"for twilliam",
"fort iwlliam",
"fort wliliam",
"fort wililam",
"fort willaim",
"fort willima",
"fort william",
"fortwilliam",
"fort william",
"dort william",
"rort william",
"tort william",
"gort william",
"vort william",
"cort william",
"firt william",
"f9rt william",
"f0rt william",
"fprt william",
"flrt william",
"fkrt william",
"foet william",
"fo4t william",
"fo5t william",
"fott william",
"fogt william",
"foft william",
"fodt william",
"forr william",
"for5 william",
"for6 william",
"fory william",
"forh william",
"forg william",
"forf william",
"fort qilliam",
"fort 2illiam",
"fort 3illiam",
"fort eilliam",
"fort dilliam",
"fort silliam",
"fort ailliam",
"fort wulliam",
"fort w8lliam",
"fort w9lliam",
"fort wolliam",
"fort wllliam",
"fort wklliam",
"fort wjlliam",
"fort wikliam",
"fort wioliam",
"fort wipliam",
"fort wilkiam",
"fort wiloiam",
"fort wilpiam",
"fort willuam",
"fort will8am",
"fort will9am",
"fort willoam",
"fort willlam",
"fort willkam",
"fort willjam",
"fort williqm",
"fort williwm",
"fort willism",
"fort willixm",
"fort willizm",
"fort willian",
"fort williaj",
"fort williak",
"dfort william",
"fdort william",
"rfort william",
"frort william",
"tfort william",
"ftort william",
"gfort william",
"fgort william",
"vfort william",
"fvort william",
"cfort william",
"fcort william",
"fiort william",
"foirt william",
"f9ort william",
"fo9rt william",
"f0ort william",
"fo0rt william",
"fport william",
"foprt william",
"flort william",
"folrt william",
"fkort william",
"fokrt william",
"foert william",
"foret william",
"fo4rt william",
"for4t william",
"fo5rt william",
"for5t william",
"fotrt william",
"fortt william",
"fogrt william",
"forgt william",
"fofrt william",
"forft william",
"fodrt william",
"fordt william",
"forrt william",
"fortr william",
"for5t william",
"fort5 william",
"for6t william",
"fort6 william",
"foryt william",
"forty william",
"forht william",
"forth william",
"forgt william",
"fortg william",
"forft william",
"fortf william",
"fort qwilliam",
"fort wqilliam",
"fort 2william",
"fort w2illiam",
"fort 3william",
"fort w3illiam",
"fort ewilliam",
"fort weilliam",
"fort dwilliam",
"fort wdilliam",
"fort swilliam",
"fort wsilliam",
"fort awilliam",
"fort wailliam",
"fort wuilliam",
"fort wiulliam",
"fort w8illiam",
"fort wi8lliam",
"fort w9illiam",
"fort wi9lliam",
"fort woilliam",
"fort wiolliam",
"fort wlilliam",
"fort willliam",
"fort wkilliam",
"fort wiklliam",
"fort wjilliam",
"fort wijlliam",
"fort wiklliam",
"fort wilkliam",
"fort wiolliam",
"fort wiloliam",
"fort wiplliam",
"fort wilpliam",
"fort wilkliam",
"fort willkiam",
"fort wiloliam",
"fort willoiam",
"fort wilpliam",
"fort willpiam",
"fort willuiam",
"fort williuam",
"fort will8iam",
"fort willi8am",
"fort will9iam",
"fort willi9am",
"fort willoiam",
"fort willioam",
"fort willliam",
"fort willilam",
"fort willkiam",
"fort willikam",
"fort willjiam",
"fort willijam",
"fort williqam",
"fort williaqm",
"fort williwam",
"fort williawm",
"fort willisam",
"fort williasm",
"fort willixam",
"fort williaxm",
"fort willizam",
"fort williazm",
"fort willianm",
"fort williamn",
"fort williajm",
"fort williamj",
"fort williakm",
"fort williamk",
];

	if(allowedEntries.includes(value)) {

		updateState("ritafound", true);
		if(state.milestone=="findrita") {
			updateState("milestone", "exit");
		}
showSuccess();
} else {
	console.log("NotFound!");
showFail();
}
}

function showSuccess() {
terminal.style.display= "none";
success.style.display="block";
}

function showFail() {
	terminal.style.display= "none";
	fail.style.display="block";
	setTimeout(function() {
		inputLocation.value="";
		fail.style.display="none";
		cssinterface.style.display="block";
	}, 5000)
}
}

}
		update() {
			if(newState) {
				newState=false;
			}
		}
}
