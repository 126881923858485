import Phaser from 'phaser'
import Drag from 'phaser3-rex-plugins/plugins/drag.js';

var portraitplaces=[413, 693, 971, 1256, 1534];

export default class Scene1c extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene1c"});
	}

	preload() {


	}

    create()
    {
			this.bg=this.add.image(960, 540, 'shelfclosed');
			this.tojb=this.add.image(958,710, 'doorpack', 'tojb').setInteractive({useHandCursor:true}).setAlpha(0);
			this.tojb.on('pointerdown', function() {
					this.scene.start("Scene1e");
			}, this)
			this.shelfopen=this.add.video(960,735,'openbooks').setAlpha(0);
			if(state.portraitscomplete) {
			this.shelfopenimage=this.add.image(960, 540, 'shelfopen');

			}
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene1");
			}, this);

				state.portraitorder.forEach(function (element, index) {
					console.log(element);
					this[element]=this.add.image(portraitplaces[index], 197, "objectpack", element);
					this[element].name=element;
					this[element].setX(portraitplaces[index]);
					this[element].depth=0;
					this[element].portorder=index;
					this[element].drag=new Drag(this[element], {});
					this[element].drag.setEnable(false);
					this[element].on('dragstart', function(e) {
						this[element].depth=1;
					}, this);

					this[element].on('drag', function(e) {
							this.rejigPorts(this[element].portorder, element);
							if(this[element].x>1534) {
								this[element].x=1534;
							}
							if(this[element].x<413) {
								this[element].x=413;
							}
							this[element].y=197;

				}, this);
				this[element].on('dragend', function(e) {
					this.dropPortrait(this[element]);
				}, this);
				}, this);
				this.hay.setAlpha(0);
			this.portraithb=this.add.image(971, 197, 'doorpack','portraithb').setAlpha(0.01);
			this.portraitscompleteoncreate=this.portraitscomplete;
			newState=true;
			this.bg.on("pointerdown", function() {
				console.log("click");
				//console.log(game.input.mousePointer.x + " / " + game.input.mousePointer.y);
			});

    }

		update() {
			if(newState) {


				if(state.droppedhaydn) {
					this.hay.setAlpha(1);
					if(!state.portraitscomplete) {
						state.portraitorder.forEach(function (element) {
							this[element].drag.setEnable(true);
					}, this);
					}
				}
				state.portraitorder.forEach(function (element, index) {
					this[element].setX(portraitplaces[index]);
					this[element].setY(197);
					this[element].depth=0;
					this[element].portorder=index;
				}, this);

				if(state.portraitscomplete && this.shelfopen && !this.portraitscompleteoncreate) {
					this.tojb.setAlpha(1);
					this.shelfopen.setAlpha(1);
					this.shelfopen.play();
					this.portraitscompleteoncreate=true;
				}
			newState=false;
			}

		}


		dropPortrait(element) {
			var newOrder=[];
			state.portraitorder.forEach(function (element) {
				newOrder[parseInt(this[element].x)]=this[element].name;
		}, this);
		newOrder = newOrder.filter(function (el) {
  	return el != null;
	});
		console.log(newOrder + "/" + state.portraitorder);
		if(newOrder=="bra,bee,sho,moz,hay") {
			updateState("portraitscomplete", true);
			updateState("milestone", "openjewels");
			state.portraitorder.forEach(function (element) {
				this[element].drag.setEnable(false);
		}, this);
		updateState("portraitorder", newOrder);

	} else if(newOrder!=state.portraitorder && !state.portraitscomplete) {
			updateState("portraitorder", newOrder);
		}
	}

		rejigPorts(portorder, element) {

			for(var i=0; i<5; i++) {

				//position 1 moves

				if(portorder < 1 && this[element].x>483	) {
					this[state.portraitorder[1]].x=portraitplaces[0];
				} else if (portorder < 1 && this[element].x<483	) {
					this[state.portraitorder[1]].x=portraitplaces[1];
				}

//position 2 moves

			if(portorder < 2	&& this[element].x>748	) {
				this[state.portraitorder[2]].x=portraitplaces[1];
			}

			if(portorder < 2	&& this[element].x<748	) {
					this[state.portraitorder[2]].x=portraitplaces[2];
			}

			if (portorder>0 && this[element].x<483) {
				this[state.portraitorder[0]].x=portraitplaces[1];
			}

			if (portorder>0 && this[element].x>483) {
				this[state.portraitorder[0]].x=portraitplaces[0];
			}

			//position 3 moves

						if(portorder < 3	&& this[element].x>1050	) {
							this[state.portraitorder[3]].x=portraitplaces[2];
						}

						if(portorder < 3	&& this[element].x<1050	) {
								this[state.portraitorder[3]].x=portraitplaces[3];
						}

						if (portorder>1 && this[element].x<748) {
							this[state.portraitorder[1]].x=portraitplaces[2];
						}

						if (portorder>1 && this[element].x>748) {
							this[state.portraitorder[1]].x=portraitplaces[1];
						}

						//position 3 moves

									if(portorder < 4	&& this[element].x>1315	) {
										this[state.portraitorder[4]].x=portraitplaces[3];
									}

									if(portorder < 4	&& this[element].x<1315	) {
											this[state.portraitorder[4]].x=portraitplaces[4];
									}

									if (portorder>2 && this[element].x<1050) {
										this[state.portraitorder[2]].x=portraitplaces[3];
									}

									if (portorder>2 && this[element].x>1050) {
										this[state.portraitorder[2]].x=portraitplaces[2];
									}


									if (portorder>3 && this[element].x<1315) {
										this[state.portraitorder[3]].x=portraitplaces[4];
									}

									if (portorder>3 && this[element].x>1315) {
										this[state.portraitorder[3]].x=portraitplaces[3];
									}

		}
		}
}
