import Phaser from 'phaser'

export default class Scene2c extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2c"});
	}

    create()
    {
			this.add.image(960, 540, 'plaque');

			this.openplaque=this.add.image(960, 540, 'openplaque').setAlpha(0);

			this.pickhaydn=this.add.image(925, 459,'doorpack', 'pickhaydn').setInteractive({useHandCursor: true}).setAlpha(0);
			this.pickhaydn.on('pointerdown', function(){
				this.game.inventoryAdd(this, "haydn");
				updateState("pickedhaydn", true);
			}, this);
					this.openplaquetaken=this.add.image(960, 540, 'openplaquetaken').setAlpha(0);
			this.tofeb3=this.add.image(932,423, 'doorpack', 'tofeb3').setInteractive({useHandCursor: true}).setAlpha(0);
			this.tofeb3.on('pointerdown', function(){
				 this.scene.start("Scene2f");
			}, this);

			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene2b");
			}, this);


			newState=true;
    }

		update() {
			if(newState) {

				if(state.gcomplete && !state.pickedhaydn){
					this.openplaque.setAlpha(1);
					this.pickhaydn.setAlpha(0.01);

				}
				if(state.gcomplete && state.pickedhaydn){

					this.openplaquetaken.setAlpha(1);
					this.tofeb3.setAlpha(0.01);

				}

				if(state.recorddrop) {
					this.game.grammoAudio.setVolume("0");
				} else {
					this.game.grammoAudio.setVolume("0");
				}

				if(state.gcomplete) {
						this.game.grammoAudio.setRate("0.95");
				} else {
						this.game.grammoAudio.setRate("0.1");
				}
				newState=false;
			}

		}
}
