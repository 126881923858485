import Phaser from 'phaser'



const COLOR_PRIMARY = 0x4e342e;
const COLOR_LIGHT = 0x7b5e57;
const COLOR_DARK = 0x260e04;

export default class Briefing extends Phaser.Scene
{
	constructor()
	{
		super({key: "Briefing"});
	}

	preload() {


		this.load.rexWebFont({
				google: {
						families: ['Questrial']
				},
				// testString: undefined,
				// testInterval: 20,
		});
	}


	create ()
	{

console.log("Create ran!");
		 this.add.image(960, 540, "van");
		 	  var style = { fontSize: "64px", fill: "#fff", fontFamily: "Questrial"}
		 this.countdown = this.add.text(1720, 30, "60:00", style);


		 this.gps = this.add.sprite(1820, 160, "objectpack", "gpsred").setScale(0.8);


		 this.exit = this.add.sprite(1820, 260, "objectpack", "exitred").setScale(0.8);


			this.hint = this.add.sprite(1820, 360, "objectpack", "hint").setScale(0.7).setAlpha(1);



			 this.duck = this.add.sprite(75, 1005, 'objectpack', "duck");
		  style = { fontSize: "32px", fill: "#fff", fontFamily: "Questrial"}

						this.lobbyform = this.add.dom(960, 540).createFromHTML(`<div style="width: 800px;  height: 400px; font-size: 24px;  border-radius: 25px; line-height: 32px; background-color: #eee; padding: 30px; overflow-y: scroll">

						<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">Hello Agent.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">Thank you for agreeing to take part in this mission today.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">I&rsquo;m sure you&rsquo;ll have read in the papers over the last few weeks about the strange goings on at the Palm Street Theatre.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">The famous actress Rita Vasconcellos, who was performing in a sell out run of the play &ldquo;The Pine Forest&rdquo; has disappeared - right into thin air.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">In all my years working for the agency, I've never seen anything like this.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">Obviously, we&rsquo;d have to ask some of the smartest, most cunning and adventurous people to try and crack a mystery like this . . . unfortunately they were all busy, but we're so grateful you were able to make it!</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">So . .  your mission will be:</p>
					<hr style="margin-bottom: 1rem "/>
					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem; "> <strong>To find out what happened to Rita and escape the theatre before it&rsquo;s too late.</strong></p>
					<hr style="margin-bottom: 1rem "/>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">In just a few moments, I&rsquo;ll be able to sneak you into Rita&rsquo;s dressing room - but I can&rsquo;t give you long, just 60 minutes in total. I&rsquo;ll add a timer in the top right corner of your screen so you can keep track.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">If you think you know where Rita has gone then please click on the GPS just below the clock and type in her location - you must do this to complete the mission.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">Once you&rsquo;ve done that all you have to do is find a way out of the room.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">You&rsquo;ll need to work together as a team, tell each other what you&rsquo;ve found and the things you&rsquo;ve managed to work out along the way.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">If you&rsquo;re stuck a yellow question mark symbol may appear, you can click this for a hint.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">Some items you can pick up and carry with you - these will be displayed at the bottom of the screen and everyone in the team can use them by clicking on them and dragging them to where you&rsquo;d like them to be used.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">Here&rsquo;s a rubber duck to get you started - you never know, it might come in handy.</p>

					<hr style="margin-bottom: 1rem "/>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">You may find some useful documents in the room - it&rsquo;s usually best for one person to read these out to the rest of the team.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">Feel free to use a search engine if you need information, but try and do this in a new tab.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">If the game freezes or anything strange happens - you can refresh and reload the game anytime.</p>
					<hr style="margin-bottom: 1rem "/>
					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem"><span style="color: red">PLEASE NOTE:</span>  You must have team members connected on at least three devices to complete the mission, some tasks cannot be completed with fewer connected devices.</p>
					<hr style="margin-bottom: 1rem "/>
					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">If you've understood the mission and would like to proceed, click the button below. Your team mates are waiting.</p>

					<p style="font-size: 24px; font-family: Questrial; margin-bottom: 1rem">Good Luck.</p>
					</div>`);

			this.letsgo = this.rexUI.add.roundRectangle(960, 850, 800, 100, 25, COLOR_PRIMARY).setInteractive({useHandCursor: true});
			this.letsgotext=this.add.text(960, 900, "Ok!  I understand the mission and am ready to go!", style);
			this.letsgotext.x=960-this.letsgotext.displayWidth/2;
			this.letsgotext.y=850-this.letsgotext.displayHeight/2;
			this.letsgo.on('pointerdown', function(){
				 this.scene.start("Holding");
			}, this);


	}

	update() {
		if(state.gamestarted) {
			this.lobbyform.destroy();
			this.scene.launch("Scene1");
			this.scene.start("Ui");
		}
	}
}
