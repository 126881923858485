import Phaser from 'phaser'

export default class Scene2e extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2e"});
	}

    create()
    {
			this.add.image(960, 540, 'phoneup');
			this.esmarelda=this.sound.add("esmarelda");
			this.nun=this.sound.add("nun");
			this.herring=this.sound.add("herring");
			this.notrecognised=this.sound.add("notrecognised");
			this.notrecognised.setLoop(true);

			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});

			this.backbutton.on('pointerdown', function(){
				 this.esmarelda.stop();
				 this.nun.stop();
				 this.herring.stop();
				 this.notrecognised.stop();
				 this.scene.start("Scene2d");
			}, this);

			var phonebuttons=["0","1","2","3","4","5","6","7","8","9","hash","star"];
			var phonebuttonsx=[1109, 1138, 1082, 1013, 948, 902, 888, 910, 964, 1041, 1167, 1156];
			var phonebuttonsy=[661, 471, 436, 436, 469, 524, 591, 653, 690, 693, 528, 601];
			phonebuttons.forEach(initPhoneButton, this);

			var sceneref=this;

			window.dialArray=[];

			function initPhoneButton(item, index) {
				console.log(this);
				this["phonebutton_"+item]= this.add.image(phonebuttonsx[index], phonebuttonsy[index], 'objectpack', 'p'+item).setInteractive({useHandCursor: true}).setAlpha(0.01);
				this["phonebutton_"+item].buttonvalue=item;
				this["phonebutton_"+item].on("pointerdown", function() {
					console.log(this.buttonvalue+" has been clicked");
					this.setAlpha(1);
					dialArray.push(this.buttonvalue);
					console.log(dialArray);
					this.scene.sound.play("phonetone"+this.buttonvalue);
					var timer = this.scene.time.addEvent({
						delay: 100,                // ms
						callback: function(){this.setAlpha(0.01)},
						callbackScope: this,
						loop: false
					});
					newState=true;
				});
			}
			this.alreadyplaying=false;
    }

		update() {
			if(newState) {
				if(!this.alreadyplaying) {

				if(dialArray.length==5 && (JSON.stringify(dialArray)!=JSON.stringify(["0", "0", "1", "4", "5"]) && JSON.stringify(dialArray)!=JSON.stringify(["0", "2", "0", "7", "4"]) && JSON.stringify(dialArray)!=JSON.stringify(["0", "2", "0", "7", "0"]))) {
					this.notrecognised.play();
				} else if (JSON.stringify(dialArray)==JSON.stringify(["0", "0", "1", "4", "5", "3", "1", "8", "2", "5"])) {
					console.log("Esmarelda Called");
					this.alreadyplaying=true;
					this.esmarelda.play();
					updateState("esmareldaCalled", true);


				}

				else if (JSON.stringify(dialArray)==JSON.stringify(["0", "2", "0", "7", "4", "3", "7", "7", "4", "6", "4"])) {
					console.log("Red Herrings Called");
						this.alreadyplaying=true;
						this.herring.play();
				}

				else if (JSON.stringify(dialArray)==JSON.stringify(["0", "2", "0", "7", "0", "0", "2", "4", "0", "4", "0"])) {
					console.log("Second to Nun called");
						this.alreadyplaying=true;
						this.nun.play();
				}
			}
		}
				newState=false;
			}

		}
