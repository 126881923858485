import Phaser from 'phaser'

export default class Scene3j extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3j"});
	}

    create()
    {
			this.add.image(960, 540, 'noticeboard02');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene3f");
			}, this);
			newState=true;
    }

		update() {
			if(newState) {
				newState=false;
			}

		}
}
