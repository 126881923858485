import Phaser from 'phaser'

export default class Scene2b extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2b"});
	}

    create()
    {
			this.add.image(960, 540, 'scene2bbg');

			game.grammoAudioStart();
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene2");
				 game.grammoAudioStop();
			}, this);

			this.togbuttons = this.add.sprite(624, 754, 'doorpack', 'togbuttons').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.recordhb = this.add.sprite(745, 304, 'doorpack','recordhb').setAlpha(0.01);

			this.togbuttons .on('pointerdown', function(){
						 this.scene.start("Scene2a");
			}, this);

			this.toplaque = this.add.sprite(1153, 561, 'doorpack', 'togplaque').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.toplaque .on('pointerdown', function(){
						 this.scene.start("Scene2c");
			}, this);

			this.midpic = this.add.sprite(1247, 602, 'midpic').setInteractive({useHandCursor: true}).setAlpha(0);
			this.midpic.on('pointerdown', function() {
			 this.scene.start("Scene2c");
			}, this);
			this.midnopic = this.add.sprite(1247, 602, 'midnopic').setAlpha(0);

			this.grammored=this.add.sprite(701, 750, 'objectpack',"gmreds").setAlpha(0);
			if(state.recorddrop) {
				this.grammored.setAlpha(1);
			}
				this.onoffbutton=this.add.image(979,491, 'objectpack','onoffbutton').setAlpha(0);
				this.lpvideo=this.add.video(733,285,'lpvid').setAlpha(0);
			var label;
				var buttoncount=0;
			for (var i = 1; i < 6; i++) {
				for (var j=1; j<6; j++){
				label="gm"+i+j;
				this[label] = this.add.sprite(701, 750, "objectpack", label).setAlpha(state["g"+i+j]);
				this[label].name=label;
				this[label].j=j;
				this[label].i=i;
				buttoncount=buttoncount+1;
			}
			}




			newState=true;
    }

		update() {
			if(newState) {

				if(state.recorddrop){
					this.onoffbutton.setAlpha(1);
					this.lpvideo.setAlpha(1).play(true);
				}
				if(state.gcomplete) {
					if(state.pickedhaydn){
					this.midnopic.setAlpha(1);
				} else {
					this.midpic.setAlpha(1);
				}
				}

				if(state.recorddrop) {
				this.grammored.setAlpha(1);
			}
				for (var i = 1; i < 6; i++) {
					for (var j=1; j<6; j++){

					this["gm"+i+j].setAlpha(state["g"+i+j]);
				}
				}

				if(state.recorddrop) {
					this.game.grammoAudio.setVolume("0.3");
				} else {
					this.game.grammoAudio.setVolume("0");
				}

				if(state.gcomplete) {
						this.game.grammoAudio.setRate("0.95");
				} else {
						this.game.grammoAudio.setRate("0.1");
				}

				newState=false;
			}

		}
}
