import Phaser from 'phaser'

export default class Scene2h extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2h"});
	}

    create()
    {
			this.bg=this.add.image(960, 540, 'padblank');
			this.entry=this.add.image(960, 540, 'padentry').setAlpha(0);
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
					this.backbutton.on('pointerdown', function(){
						 this.scene.start("Scene2d");
					}, this);
					newState=true;

    }

		update() {
			if(newState) {
				if(state.esmareldaCalled) {
					this.entry.setAlpha(1);
				}
				newState=false;
			}
		}
}
