import Phaser from 'phaser'

export default class Scene3g extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3g"});
	}

    create()
    {
			this.add.image(960, 540, 'scene3cardsclear');
		  this.cardscoffee=this.add.image(960, 540, 'scene3cardscoffee');
			this.wighb = this.add.sprite(1522, 630, 'doorpack','cardshb').setAlpha(0.01 );
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene3b");
			}, this);
			newState=true;
    }

		update() {
			if(newState) {

				if(state.clearedcards) {
					this.cardscoffee.setAlpha(0);
				}
				newState=false;
			}

		}
}
