import Phaser from 'phaser'


export default class Preloader extends Phaser.Scene
{
	constructor()
	{
		super('Preloader');
	}



  preload() {
				console.log("Preload!");
			 this.add.image(960, 540, "intro");
        var progressBar = this.add.graphics();
        var progressBox = this.add.graphics();

        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(710, 270, 500, 50);


        var width = this.cameras.main.width;
        var height = this.cameras.main.height;
        var loadingText = this.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'Loading... please stay on this tab until you reach the briefing room',
            style: {
                font: '20px monospace',
                fill: '#ffffff'
            }
        });
        loadingText.setOrigin(0.5, 0.5);

        var percentText = this.make.text({
            x: width / 2,
            y: height / 2 - 5,
            text: '0%',
            style: {
                font: '18px monospace',
                fill: '#ffffff'
            }
        });
        percentText.setOrigin(0.5, 0.5);

        var assetText = this.make.text({
            x: width / 2,
            y: height / 2 + 50,
            text: '',
            style: {
                font: '18px monospace',
                fill: '#ffffff'
            }
        });

        assetText.setOrigin(0.5, 0.5);

        this.load.on('progress', function (value) {

            percentText.setText(parseInt(value * 100) + '%');

            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(710, 280, 500 * value, 30);

        });
/*
        this.load.on('start', function (file) {
					//console.log("Load started");
        });

				this.load.on('addfile', function (key) {
				//	console.log("Add file "+ key);
				});

				this.load.on('filecomplete', function (key) {
				//	console.log("Filecomplete: "+key);
				});

				this.load.on('loaderror', function (file) {
				//	console.log("Load Error: "+file.key);
				});

				this.load.on('fileprogress', function (file, progress) {
				//	console.log("File Progress: "+file.key+" ("+progress+")");
				});

*/



				// create inventory2
				//music


				//Buttons
				/*
				this.load.image('backbutton', require('../../assets/buttons/backbutton2.png'));
				this.load.image('greset', require('../../assets/buttons/greset.jpg'));
				*/
				//backgrounds


        this.load.image('scene1bg', require('../../assets/bg/scene1.jpg'));
				this.load.image('scene1abg', require('../../assets/bg/scene1a.jpg'));
				this.load.image('scene1abg2', require('../../assets/bg/scene1abg2.jpg'));
				this.load.image('scene1abg3', require('../../assets/bg/scene1abg3.jpg'));
				this.load.image('scene1bbg', require('../../assets/bg/scene1bbg.jpg'));
				this.load.image('scene1cbg', require('../../assets/bg/scene1cbg.jpg'));
				this.load.image('scene1ibg', require('../../assets/bg/scene1ibg.jpg'));
        this.load.image('scene2bg', require('../../assets/bg/scene2.jpg'));
				this.load.image('scene2bbg', require('../../assets/gramophone/scene2bbg.jpg'));
        this.load.image('scene3bg', require('../../assets/bg/scene3.jpg'));
				this.load.image('scene3bbg', require('../../assets/bg/scene3bbg.jpg'));
				this.load.image('plaque', require('../../assets/bg/plaquenew.jpg'));
				this.load.image('openplaque', require('../../assets/gramophone/openplaque.jpg'));
				this.load.image('openplaquetaken', require('../../assets/gramophone/openplaquetaken.jpg'));

				this.load.image('scene2abg', require('../../assets/gramophone/bgred.jpg'));
				this.load.image('dtablebg', require('../../assets/dresser/dtablebg.jpg'));
				this.load.image('dtableletter', require('../../assets/dresser/dtableletter.jpg'));
				this.load.image('dtablepostcard', require('../../assets/dresser/dtablepostcard.jpg'));
				this.load.image('dtablewig', require('../../assets/dresser/dtablewig.jpg'));
				this.load.image('scene2abgpre', require('../../assets/gramophone/bgpre.jpg'));
				this.load.image('safebg', require('../../assets/safe/safebg.jpg'));
				this.load.image('sdial', require('../../assets/safe/sdial.png'));
				this.load.image('safeopen', require('../../assets/safe/safeopen.jpg'));
				this.load.image('safeopenrecord', require('../../assets/safe/safeopenrecord.jpg'));
				this.load.image('lpoverlay', require('../../assets/safe/lpoverlay.jpg'));
				this.load.image('caseopen', require('../../assets/trophycase/opencase.jpg'));
				this.load.image('pipecover', require('../../assets/trophycase/pipe.jpg'));
				this.load.image('sarcbg', require('../../assets/bg/sarcbg.jpg'));
				this.load.image('flowersbg', require('../../assets/bg/flowersbg.jpg'));
				this.load.image('letterbg', require('../../assets/bg/letterbg.jpg'));
				this.load.image('postcardbg', require('../../assets/bg/postcardbg.jpg'));
				this.load.image('scene3cardscoffee', require('../../assets/bg/scene3cardscoffee.jpg'));
				this.load.image('scene3cardsclear', require('../../assets/bg/scene3cardsclear.jpg'));
				this.load.image('scene3diary', require('../../assets/bg/scene3diary.jpg'));
				this.load.image('cc', require('../../assets/bg/cc.jpg'));
				this.load.image('organbg2', require('../../assets/bg/organbg2.jpg'));
				this.load.image('organbg2_open', require('../../assets/bg/organbg2_open.jpg'));
				this.load.image('widebooksopen', require('../../assets/bg/widebooksopen.jpg'));
				this.load.image('scene1ibg', require('../../assets/bg/scene1ibg.jpg'));
				this.load.image('scene1jbg', require('../../assets/bg/scene1jbg.jpg'));
			  this.load.image('scene2fbg', require('../../assets/bg/scene2fbg.jpg'));
				this.load.image('scene2gbg', require('../../assets/bg/scene2gbg.jpg'));
				this.load.image('blacklightroom', require('../../assets/bg/blacklightroom.jpg'));
				this.load.image('blacklightmirror', require('../../assets/bg/blacklightmirror.jpg'));
				this.load.image('scene3darkbg', require('../../assets/bg/scene3darkbg.jpg'));
				this.load.image('cleanedcoffee', require('../../assets/basementtable/cleanedcoffee.jpg'));
				this.load.image('blacklight', require('../../assets/organconsole/blacklight.jpg'));
				this.load.image('feb10bg', require('../../assets/bg/feb10bg.jpg'));
				this.load.image('endingdooropen', require('../../assets/bg/endingdooropen.jpg'));
				this.load.image('briefing', require('../../assets/bg/briefing.jpg'));
				this.load.image('grammoopena', require('../../assets/bg/gramdoora.jpg'));
				this.load.image('grammoopenb', require('../../assets/bg/gramdoorb.jpg'));

				//stateful scene objects
				this.load.image('sc1_safe1', require('../../assets/bg/sc1_safe1.jpg'));
				this.load.image('sc1_safe2', require('../../assets/bg/sc1_safe2.jpg'));
				this.load.image('gcupic', require('../../assets/gramophone/cupic.jpg'));
				this.load.image('gcunopic', require('../../assets/gramophone/cunopic.jpg'));
				this.load.image('midpic', require('../../assets/gramophone/midpic.png'));
				this.load.image('midnopic', require('../../assets/gramophone/midnopic.png'));
				this.load.image('wigcover', require('../../assets/bg/wigcover.jpg'));
				this.load.image('gpsbg', require('../../assets/bg/gpsbg.jpg'));
				this.load.image('noticeboard01', require('../../assets/bg/noticeboard01.jpg'));
				this.load.image('noticeboard02', require('../../assets/bg/noticeboard02.jpg'));
				this.load.image('padblank', require('../../assets/bg/padblank.jpg'));
				this.load.image('padentry', require('../../assets/bg/padentry.jpg'));
				this.load.image('van', require('../../assets/briefing/van.jpg'));



					//portraits

					this.load.image('shelfopen', require('../../assets/portraits/shelfopen.jpg'));
					this.load.image('shelfclosed', require('../../assets/portraits/shelfclosed.jpg'));
					/*
					this.load.image('bra', require('../../assets/portraits/bra.png'));
					this.load.image('bee', require('../../assets/portraits/bee.png'));
					this.load.image('sho', require('../../assets/portraits/sho.png'));
					this.load.image('moz', require('../../assets/portraits/moz.png'));
					this.load.image('hay', require('../../assets/portraits/hay.png'));
					this.load.image('wbra', require('../../assets/portraits/wide/bra.png'));
					this.load.image('wbee', require('../../assets/portraits/wide/bee.png'));
					this.load.image('wsho', require('../../assets/portraits/wide/sho.png'));
					this.load.image('wmoz', require('../../assets/portraits/wide/moz.png'));
					this.load.image('whay', require('../../assets/portraits/wide/hay.png'));
					*/

/*

				//doors
        this.load.image('doorpack', 'toscene2', require('../../assets/doors/toscene2.png'));
				this.load.image('doorpack', 'toscene1', require('../../assets/doors/toscene1.jpg'));
				this.load.image('doorpack', 'toscene3', require('../../assets/doors/toscene3.jpg'));
				this.load.image('backup', require('../../assets/doors/backtoscene2.png'));
				this.load.image('doorpack', 'tosafe', require('../../assets/doors/tosafedoor.jpg'));
				*/
				this.load.image('opensafe', require('../../assets/doors/tosafe.jpg'));
				/*
				this.load.image('doorpack', 'tobox', require('../../assets/doors/tobox.png'));
				this.load.image('doorpack', 'toorgan', require('../../assets/doors/toorgan.png'));
				this.load.image('doorpack', 'toportraits', require('../../assets/doors/toportraits.png'));
				this.load.image('doorpack', 'totrophies', require('../../assets/doors/totrophies.png'));
				this.load.image('doorpack', 'todresser', require('../../assets/doors/todresser.png'));
			  this.load.image('doorpack', 'tobtable', require('../../assets/doors/tobtable.png'));
				this.load.image('doorpack', 'topipes', require('../../assets/doors/topipes.png'));
				this.load.image('doorpack', 'tosarc', require('../../assets/doors/tosarc.png'));
				this.load.image('doorpack', 'tophone', require('../../assets/doors/tophone.png'));
				this.load.image('doorpack', 'togbuttons', require('../../assets/doors/togbuttons.png'));
				this.load.image('doorpack', 'togplaque', require('../../assets/doors/togplaque.png'));
				this.load.image('doorpack', 'tojb', require('../../assets/doors/tojb.png'));
				this.load.image('doorpack', 'topostcard', require('../../assets/doors/topostcard.png'));
				this.load.image('doorpack', 'toletter', require('../../assets/doors/toletter.png'));
				this.load.image('doorpack', 'toflowers', require('../../assets/doors/toflowers.png'));
				this.load.image('doorpack', 'toritacards', require('../../assets/doors/toritacards.png'));
				this.load.image('doorpack', 'toritaletters', require('../../assets/doors/toritaletters.png'));
				this.load.image('doorpack', 'tonoticeboard', require('../../assets/doors/tonoticeboard.png'));
				this.load.image('doorpack', 'tophoneup', require('../../assets/doors/tophoneup.png'));
				this.load.image('doorpack', 'tojan13', require('../../assets/doors/tojan13.png'));
				this.load.image('doorpack', 'tofeb3', require('../../assets/doors/tofeb3.png'));
				this.load.image('doorpack', 'tofeb10', require('../../assets/doors/tofeb10.png'));
				this.load.image('doorpack', 'tomar1', require('../../assets/doors/tomar1.png'));
				this.load.image('doorpack', 'tocredits', require('../../assets/doors/tocredits.png'));



				//hitboxes
				this.load.image('lphb', require('../../assets/hitboxes/lp_857_591.jpg'));
				this.load.image('lpdb', require('../../assets/hitboxes/lpdb.jpg'));
				this.load.image('warddoor', require('../../assets/hitboxes/s2door.jpg'));
			  this.load.image('recordhb', require('../../assets/hitboxes/recordhb.png'));
			  this.load.image('portraithb', require('../../assets/hitboxes/portraithb.png'));
			  this.load.image('wighb', require('../../assets/hitboxes/wighb.jpg'));
				this.load.image('cardhb', require('../../assets/hitboxes/wighb.png'));
				this.load.image('pipesdb', require('../../assets/hitboxes/pipedb.png'));
			  this.load.image('pickhaydn', require('../../assets/hitboxes/pickhaydn.png'));
				this.load.image('pickkey', require('../../assets/hitboxes/pickkey.png'));
				this.load.image('keydb', require('../../assets/hitboxes/keydb.png'));
				this.load.image('bulbdb', require('../../assets/hitboxes/bulbdb.png'));
				this.load.image('bulbdb2', require('../../assets/hitboxes/bulbdb2.png'));

*/
/*
				//grammobuttons

					this.load.image('g11',require('../../assets/gramophone/Buttons2/1.jpg'));
					this.load.image('g12',require('../../assets/gramophone/Buttons2/2.jpg'));
					this.load.image('g13',require('../../assets/gramophone/Buttons2/3.jpg'));
					this.load.image('g14',require('../../assets/gramophone/Buttons2/4.jpg'));
					this.load.image('g15',require('../../assets/gramophone/Buttons2/5.jpg'));
					this.load.image('g21',require('../../assets/gramophone/Buttons2/6.jpg'));
					this.load.image('g22',require('../../assets/gramophone/Buttons2/7.jpg'));
					this.load.image('g23',require('../../assets/gramophone/Buttons2/8.jpg'));
					this.load.image('g24',require('../../assets/gramophone/Buttons2/9.jpg'));
					this.load.image('g25',require('../../assets/gramophone/Buttons2/10.jpg'));
					this.load.image('g31',require('../../assets/gramophone/Buttons2/11.jpg'));
					this.load.image('g32',require('../../assets/gramophone/Buttons2/12.jpg'));
					this.load.image('g33',require('../../assets/gramophone/Buttons2/13.jpg'));
					this.load.image('g34',require('../../assets/gramophone/Buttons2/14.jpg'));
					this.load.image('g35',require('../../assets/gramophone/Buttons2/15.jpg'));
					this.load.image('g41',require('../../assets/gramophone/Buttons2/16.jpg'));
					this.load.image('g42',require('../../assets/gramophone/Buttons2/17.jpg'));
					this.load.image('g43',require('../../assets/gramophone/Buttons2/18.jpg'));
					this.load.image('g44',require('../../assets/gramophone/Buttons2/19.jpg'));
					this.load.image('g45',require('../../assets/gramophone/Buttons2/20.jpg'));
					this.load.image('g51',require('../../assets/gramophone/Buttons2/21.jpg'));
					this.load.image('g52',require('../../assets/gramophone/Buttons2/22.jpg'));
					this.load.image('g53',require('../../assets/gramophone/Buttons2/23.jpg'));
					this.load.image('g54',require('../../assets/gramophone/Buttons2/24.jpg'));
					this.load.image('g55',require('../../assets/gramophone/Buttons2/25.jpg'));

					//buttonswide

					this.load.image('gw11',require('../../assets/gramophone/buttonswide/1.png'));
					this.load.image('gw12',require('../../assets/gramophone/buttonswide/2.png'));
					this.load.image('gw13',require('../../assets/gramophone/buttonswide/3.png'));
					this.load.image('gw14',require('../../assets/gramophone/buttonswide/4.png'));
					this.load.image('gw15',require('../../assets/gramophone/buttonswide/5.png'));
					this.load.image('gw21',require('../../assets/gramophone/buttonswide/6.png'));
					this.load.image('gw22',require('../../assets/gramophone/buttonswide/7.png'));
					this.load.image('gw23',require('../../assets/gramophone/buttonswide/8.png'));
					this.load.image('gw24',require('../../assets/gramophone/buttonswide/9.png'));
					this.load.image('gw25',require('../../assets/gramophone/buttonswide/10.png'));
					this.load.image('gw31',require('../../assets/gramophone/buttonswide/11.png'));
					this.load.image('gw32',require('../../assets/gramophone/buttonswide/12.png'));
					this.load.image('gw33',require('../../assets/gramophone/buttonswide/13.png'));
					this.load.image('gw34',require('../../assets/gramophone/buttonswide/14.png'));
					this.load.image('gw35',require('../../assets/gramophone/buttonswide/15.png'));
					this.load.image('gw41',require('../../assets/gramophone/buttonswide/16.png'));
					this.load.image('gw42',require('../../assets/gramophone/buttonswide/17.png'));
					this.load.image('gw43',require('../../assets/gramophone/buttonswide/18.png'));
					this.load.image('gw44',require('../../assets/gramophone/buttonswide/19.png'));
					this.load.image('gw45',require('../../assets/gramophone/buttonswide/20.png'));
					this.load.image('gw51',require('../../assets/gramophone/buttonswide/21.png'));
					this.load.image('gw52',require('../../assets/gramophone/buttonswide/22.png'));
					this.load.image('gw53',require('../../assets/gramophone/buttonswide/23.png'));
					this.load.image('gw54',require('../../assets/gramophone/buttonswide/24.png'));
					this.load.image('gw55',require('../../assets/gramophone/buttonswide/25.png'));
					this.load.image('gwreds',require('../../assets/gramophone/buttonswide/reds.png'));

					//buttonsmid

					this.load.image('gm11',require('../../assets/gramophone/buttonsmid/1.png'));
					this.load.image('gm12',require('../../assets/gramophone/buttonsmid/2.png'));
					this.load.image('gm13',require('../../assets/gramophone/buttonsmid/3.png'));
					this.load.image('gm14',require('../../assets/gramophone/buttonsmid/4.png'));
					this.load.image('gm15',require('../../assets/gramophone/buttonsmid/5.png'));
					this.load.image('gm21',require('../../assets/gramophone/buttonsmid/6.png'));
					this.load.image('gm22',require('../../assets/gramophone/buttonsmid/7.png'));
					this.load.image('gm23',require('../../assets/gramophone/buttonsmid/8.png'));
					this.load.image('gm24',require('../../assets/gramophone/buttonsmid/9.png'));
					this.load.image('gm25',require('../../assets/gramophone/buttonsmid/10.png'));
					this.load.image('gm31',require('../../assets/gramophone/buttonsmid/11.png'));
					this.load.image('gm32',require('../../assets/gramophone/buttonsmid/12.png'));
					this.load.image('gm33',require('../../assets/gramophone/buttonsmid/13.png'));
					this.load.image('gm34',require('../../assets/gramophone/buttonsmid/14.png'));
					this.load.image('gm35',require('../../assets/gramophone/buttonsmid/15.png'));
					this.load.image('gm41',require('../../assets/gramophone/buttonsmid/16.png'));
					this.load.image('gm42',require('../../assets/gramophone/buttonsmid/17.png'));
					this.load.image('gm43',require('../../assets/gramophone/buttonsmid/18.png'));
					this.load.image('gm44',require('../../assets/gramophone/buttonsmid/19.png'));
					this.load.image('gm45',require('../../assets/gramophone/buttonsmid/20.png'));
					this.load.image('gm51',require('../../assets/gramophone/buttonsmid/21.png'));
					this.load.image('gm52',require('../../assets/gramophone/buttonsmid/22.png'));
					this.load.image('gm53',require('../../assets/gramophone/buttonsmid/23.png'));
					this.load.image('gm54',require('../../assets/gramophone/buttonsmid/24.png'));
					this.load.image('gm55',require('../../assets/gramophone/buttonsmid/25.png'));
					this.load.image('gmreds',require('../../assets/gramophone/buttonsmid/reds.png'));

					//trophycase

					this.load.image('k11',require('../../assets/trophycase/Buttons2/11.jpg'));
					this.load.image('k12',require('../../assets/trophycase/Buttons2/12.jpg'));
					this.load.image('k13',require('../../assets/trophycase/Buttons2/13.jpg'));
					this.load.image('k21',require('../../assets/trophycase/Buttons2/21.jpg'));
					this.load.image('k22',require('../../assets/trophycase/Buttons2/22.jpg'));
					this.load.image('k23',require('../../assets/trophycase/Buttons2/23.jpg'));
					this.load.image('k31',require('../../assets/trophycase/Buttons2/31.jpg'));
					this.load.image('k32',require('../../assets/trophycase/Buttons2/32.jpg'));
					this.load.image('k33',require('../../assets/trophycase/Buttons2/33.jpg'));
					this.load.image('k41',require('../../assets/trophycase/Buttons2/ask.jpg'));
					this.load.image('k42',require('../../assets/trophycase/Buttons2/0.jpg'));
					this.load.image('k43',require('../../assets/trophycase/Buttons2/hash.jpg'));

					//phonebuttons

					this.load.image('phonebuttonstar',require('../../assets/telephone/buttons/star.jpg'));
					this.load.image('phonebutton0',require('../../assets/telephone/buttons/0.jpg'));
					this.load.image('phonebutton1',require('../../assets/telephone/buttons/1.jpg'));
					this.load.image('phonebutton2',require('../../assets/telephone/buttons/2.jpg'));
					this.load.image('phonebutton3',require('../../assets/telephone/buttons/3.jpg'));
					this.load.image('phonebutton4',require('../../assets/telephone/buttons/4.jpg'));
					this.load.image('phonebutton5',require('../../assets/telephone/buttons/5.jpg'));
					this.load.image('phonebutton6',require('../../assets/telephone/buttons/6.jpg'));
					this.load.image('phonebutton7',require('../../assets/telephone/buttons/7.jpg'));
					this.load.image('phonebutton8',require('../../assets/telephone/buttons/8.jpg'));
					this.load.image('phonebutton9',require('../../assets/telephone/buttons/9.jpg'));
					this.load.image('phonebuttonhash',require('../../assets/telephone/buttons/hash.jpg'));
					*/

					//s2door

							this.load.image('warddoor', require('../../assets/hitboxes/s2door.jpg'));

					//jewelbox

					this.load.image('boxcu',require('../../assets/jewelbox/boxcu.jpg'));
         /*
					this.load.image('slidere',require('../../assets/jewelbox/slidere.png'));
					this.load.image('slidernw',require('../../assets/jewelbox/slidernw.png'));
					this.load.image('slidersw',require('../../assets/jewelbox/slidersw.png'));
					this.load.image('centrea',require('../../assets/jewelbox/centrea.png'));
					this.load.image('centreb',require('../../assets/jewelbox/centreb.png'));
        */
					this.load.image('jbopen',require('../../assets/jewelbox/jbopen.jpg'));
					this.load.image('jbkey',require('../../assets/jewelbox/jbkey.jpg'));

							//lightswitch

					this.load.image('lsbase',require('../../assets/lswitch/base.jpg'));
					/*
					this.load.image('l0',require('../../assets/lswitch/l0.jpg'));
					this.load.image('l1',require('../../assets/lswitch/l1.jpg'));
					this.load.image('l2',require('../../assets/lswitch/l2.jpg'));
					this.load.image('l3',require('../../assets/lswitch/l3.jpg'));
					this.load.image('l4',require('../../assets/lswitch/l4.jpg'));
					this.load.image('l5',require('../../assets/lswitch/l5.jpg'));
					this.load.image('bottom',require('../../assets/lswitch/bottom.png'));
					this.load.image('doorpack', 'top',require('../../assets/lswitch/top.png'));
					this.load.image('left',require('../../assets/lswitch/left.png'));
					this.load.image('right',require('../../assets/lswitch/right.png'));
					this.load.image('buttonup',require('../../assets/lswitch/buttonup.jpg'));

*/

//MULTIATLAS FOR GAME OBJECTS

				  this.load.multiatlas('objectpack', 'objects.json');
					this.load.multiatlas('doorpack', 'doors.json');
					this.load.multiatlas('magic1', 'magic1.json');







					//phonedown

					this.load.image('phonedown',require('../../assets/telephone/phonedown.jpg'));
					this.load.image('phoneup',require('../../assets/telephone/phoneup.jpg'));


/*

				//inventory
				this.load.image('duck', require('../../assets/inventory/duck.png'));
				this.load.image('lp', require('../../assets/inventory/lp.png'));
				this.load.image('haydn', require('../../assets/inventory/haydn.png'));
				this.load.image('pipe', require('../../assets/inventory/pipe.png'));
				this.load.image('key', require('../../assets/inventory/key.png'));
				this.load.image('bulb', require('../../assets/inventory/blacklight.png'));
				this.load.image('wig', require('../../assets/inventory/wig.png'));
				*/

				//Organ
				this.load.image('organbg', require('../../assets/organ/organ.jpg'));
				/*
				this.load.image('pes', require('../../assets/organ/pipes/pes.png'));
				this.load.image('pew', require('../../assets/organ/pipes/pew.png'));
				this.load.image('pne', require('../../assets/organ/pipes/pne.png'));
				this.load.image('psw', require('../../assets/organ/pipes/psw.png'));
				this.load.image('pwn', require('../../assets/organ/pipes/pwn.png'));
				this.load.image('pns', require('../../assets/organ/pipes/pns.png'));
			  this.load.image('gns', require('../../assets/organ/pipes/gns.png'));
				this.load.image('gew', require('../../assets/organ/pipes/gew.png'));

				this.load.image('peswide', require('../../assets/organwide/pipes/pes.png'));
				this.load.image('pewwide', require('../../assets/organwide/pipes/pew.png'));
				this.load.image('pnewide', require('../../assets/organwide/pipes/pne.png'));
				this.load.image('pswwide', require('../../assets/organwide/pipes/psw.png'));
				this.load.image('pwnwide', require('../../assets/organwide/pipes/pwn.png'));
				this.load.image('pnswide', require('../../assets/organwide/pipes/pns.png'));
				this.load.image('gnswide', require('../../assets/organwide/pipes/gns.png'));
				this.load.image('gewwide', require('../../assets/organwide/pipes/gew.png'));

				//ending



					this.load.image('h1',require('../../assets/ending/h1.jpg'));
					this.load.image('h2',require('../../assets/ending/h2.jpg'));
					this.load.image('h3',require('../../assets/ending/h3.jpg'));
					this.load.image('h4',require('../../assets/ending/h4.jpg'));
					this.load.image('h5',require('../../assets/ending/h5.jpg'));
					this.load.image('h6',require('../../assets/ending/h6.jpg'));

					this.load.image('h1g',require('../../assets/ending/h1g.jpg'));
					this.load.image('h2g',require('../../assets/ending/h2g.jpg'));
					this.load.image('h3g',require('../../assets/ending/h3g.jpg'));
					this.load.image('h4g',require('../../assets/ending/h4g.jpg'));
					this.load.image('h5g',require('../../assets/ending/h5g.jpg'));
					this.load.image('h6g',require('../../assets/ending/h6g.jpg'));

*/



				//sfx
				this.load.audio('keypadbeep', require('../../assets/sfx/keypadbeep.wav'));
				this.load.audio('keypadunlock', require('../../assets/sfx/unlock.wav'));
			  this.load.audio('keypadbuzzer', require('../../assets/sfx/buzzer.wav'));

			const urlparams = new URLSearchParams(document.location.search.substring(1));

if(urlparams.get("noload")!="video" && urlparams.get("noload")!="all") {



		this.load.video('ending',require('../../assets/video/ending.mp4'), 'canplaythrough', true, false);

		this.load.video('lightswig', require('../../assets/video/lightswig.mp4'), 'canplaythrough', true, false);
		this.load.video('lightsnowig', require('../../assets/video/lightsnowig.mp4'), 'canplaythrough', true, false);
		this.load.video('safeopenvideo', require('../../assets/video/safeopenvideo.mp4'), 'canplaythrough', true, false);
		this.load.video('openbooks', require('../../assets/video/open.mp4'), 'canplaythrough', true, false);
		this.load.video('jewelopen',require('../../assets/video/jewelopen.mp4'), 'canplaythrough', true, false);

		this.load.video('baselightson', require('../../assets/video/baselightson.mp4'), 'canplaythrough', true, false);
		this.load.video('lpvid', require('../../assets/video/lpvid.mp4'), 'canplaythrough', true, false);
		this.load.video('recordspinwide', require('../../assets/video/recordspinwide.mp4'), 'canplaythrough', true, false);

		this.load.video('wardopenvideo', require('../../assets/video/wardopenvideo.mp4'), 'canplaythrough', true, false);









		//video
/*
		this.load.video('ml1',require('../../assets/video/ml1.mp4'), 'canplaythrough', true, false);


		this.load.video('ml2',require('../../assets/video/ml2.mp4'), 'canplaythrough', true, false);

		this.load.video('ml3',require('../../assets/video/ml3.mp4'), 'canplaythrough', true, false);


		this.load.video('ml4',require('../../assets/video/ml4.mp4'), 'canplaythrough', true, false);


		this.load.video('ml5',require('../../assets/video/ml5.mp4'), 'canplaythrough', true, false);

		*/

		this.load.image('ml1',require('../../assets/magicpng/ml1.png'));

		this.load.image('ml2',require('../../assets/magicpng/ml2.png'));

		this.load.image('ml3',require('../../assets/magicpng/ml3.png'));


		this.load.image('ml4',require('../../assets/magicpng/ml4.png'));


		this.load.image('ml5',require('../../assets/magicpng/ml5.png'));

		//this.load.video('ml6',require('../../assets/video/ml6.mp4'), 'canplaythrough', true, false);
/*
		this.load.video('ms6',require('../../assets/video/ms6.mp4'), 'canplaythrough', true, false);
		this.load.video('ms5',require('../../assets/video/ms5.mp4'), 'canplaythrough', true, false);
		this.load.video('ms4',require('../../assets/video/ms4.mp4'), 'canplaythrough', true, false);
		this.load.video('ms3',require('../../assets/video/ms3.mp4'), 'canplaythrough', true, false);
		this.load.video('ms2',require('../../assets/video/ms2.mp4'), 'canplaythrough', true, false);
	this.load.video('ms1',require('../../assets/video/ms1.mp4'), 'canplaythrough', true, false);
	*/

		this.load.video('ending',require('../../assets/video/ending.mp4'), 'canplaythrough', true, false);
			this.load.video('train',require('../../assets/video/train.mp4'), 'canplaythrough', true, false);

		this.load.video('lightswig', require('../../assets/video/lightswig.mp4'), 'canplaythrough', true, false);
		this.load.video('lightsnowig', require('../../assets/video/lightsnowig.mp4'), 'canplaythrough', true, false);
		this.load.video('safeopenvideo', require('../../assets/video/safeopenvideo.mp4'), 'canplaythrough', true, false);
		this.load.video('openbooks', require('../../assets/video/open.mp4'), 'canplaythrough', true, false);
		this.load.video('jewelopen',require('../../assets/video/jewelopen.mp4'), 'canplaythrough', true, false);

		this.load.video('baselightson', require('../../assets/video/baselightson.mp4'), 'canplaythrough', true, false);
		this.load.video('lpvid', require('../../assets/video/lpvid.mp4'), 'canplaythrough', true, false);
		this.load.video('recordspinwide', require('../../assets/video/recordspinwide.mp4'), 'canplaythrough', true, false);

		this.load.video('wardopenvideo', require('../../assets/video/wardopenvideo.mp4'), 'canplaythrough', true, false);

}



					// audio

	if(urlparams.get("noload")!="audio" && urlparams.get("noload")!="all") {				//phonesounds

						this.load.audio('phonetonestar', require('../../assets/telephone/dtmf/dtmf-star.mp3'));
						this.load.audio('phonetone0', require('../../assets/telephone/dtmf/dtmf-0.mp3'));
						this.load.audio('phonetone1', require('../../assets/telephone/dtmf/dtmf-1.mp3'));
						this.load.audio('phonetone2', require('../../assets/telephone/dtmf/dtmf-2.mp3'));
						this.load.audio('phonetone3', require('../../assets/telephone/dtmf/dtmf-3.mp3'));
						this.load.audio('phonetone4', require('../../assets/telephone/dtmf/dtmf-4.mp3'));
						this.load.audio('phonetone5', require('../../assets/telephone/dtmf/dtmf-5.mp3'));
						this.load.audio('phonetone6', require('../../assets/telephone/dtmf/dtmf-6.mp3'));
						this.load.audio('phonetone7', require('../../assets/telephone/dtmf/dtmf-7.mp3'));
						this.load.audio('phonetone8', require('../../assets/telephone/dtmf/dtmf-8.mp3'));
						this.load.audio('phonetone9', require('../../assets/telephone/dtmf/dtmf-9.mp3'));
						this.load.audio('phonetonehash', require('../../assets/telephone/dtmf/dtmf-hash.mp3'));


//vo

/*

this.load.audio('ritaletter', require('../../assets/vo/letter.m4a'));
this.load.audio('vo13jan', require('../../assets/vo/13jan.m4a'));
this.load.audio('vo03feb', require('../../assets/vo/03feb.m4a'));
this.load.audio('vo10feb', require('../../assets/vo/10feb.m4a'));
this.load.audio('vo01mar', require('../../assets/vo/01mar.m4a'));
this.load.audio('vo06mar', require('../../assets/vo/06mar.m4a'));
*/
this.load.audio('esmarelda', require('../../assets/vo/esmarelda.m4a'));
this.load.audio('classics', require('../../assets/vo/classics.m4a'));
this.load.audio('herring', require('../../assets/vo/herrings.m4a'));
this.load.audio('nun', require('../../assets/vo/nun.m4a'));
this.load.audio('notrecognised', require('../../assets/vo/notrecognised.m4a'));

this.load.audio('organ', require('../../assets/sfx/organ.wav'));

//this.load.audio('rain', require('../../assets/ambience/rain.mp3'));
//this.load.audio('theatre', require('../../assets/ambience/theatreshort.mp3'));

//music
//this.load.audio('cue1', require('../../assets/music/cue1.mp3'));
// this.load.audio('music', require('../../assets/music/music.mp3'));
//this.load.audio('hitman', require('../../assets/music/music.mp3'));
}





				//cursors

				this.load.image('crosshairs', require('../../assets/cursors/crosshairs.png'));

				   this.load.on('loaderror', function(file) {
						 console.log("Error loading: "+file);

					 }, this);

        this.load.on('complete', function () {
					console.log("Load Complete!");
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
            percentText.destroy();
            assetText.destroy();
						if(state.gamestarted) {
							if(state.endtime>0) {
								this.scene.start("Credits");
							} else if(state.gamelost) {
								this.scene.start("Losscredits");
							} else {
							this.scene.launch("Scene1");
							this.scene.start("Ui");
						 }
						} else {
            this.scene.start("Briefing");
					}
        }, this);
    }

}
