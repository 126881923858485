import Phaser from 'phaser'

export default class Scene2d extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2d"});
	}

    create()
    {
			this.add.image(960, 540, 'phonedown');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.tophoneup=this.add.image(1203, 366, 'doorpack', 'tophoneup').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.topad=this.add.image(1719, 890, 'objectpack', 'padpatch').setInteractive({useHandCursor: true}).setAlpha(0.01);


			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene2");
			}, this);

			this.topad.on('pointerdown', function(){
				 this.scene.start("Scene2h");
			}, this);

			this.tophoneup.on('pointerdown', function() {
				this.scene.start("Scene2e");
				console.log("Phone clicked!");
			}, this);
			newState=true;
    }

		update() {
			if(newState) {
				if(state.esmareldaCalled) {
					this.topad.setAlpha(1);
				}

				newState=false;
			}

		}
}
