import Phaser from 'phaser'

export default class Scene1i extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene1i"});
	}

    create()
    {
			this.add.image(960, 540, 'scene1ibg');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene1a");
			}, this);
				game.launchAudio("vo13jan", "Diary: January 13th");
			newState=true;
    }

		update() {
			if(newState) {

				newState=false;
			}

		}
}
