import Phaser from 'phaser'
import axios from 'axios'

const COLOR_PRIMARY = 0x4e342e;
const COLOR_LIGHT = 0x7b5e57;
const COLOR_DARK = 0x260e04;
const COLOR_RED= 0xd43b3b;

export default class Losscredits extends Phaser.Scene
{
	constructor()
	{
		super({key: "Losscredits"});
	}

	preload() {

		this.load.rexWebFont({
				google: {
						families: ['Questrial']
				},
				// testString: undefined,
				// testInterval: 20,
		});
		console.log("Credits!");
		updateStatus("game_lost");
	}


	create ()
	{
var style = { fontSize: "32px", fill: "#fff", fontFamily: "Questrial",  wordWrap: { width: 750 }}
			this.bg=	this.add.image(960, 540, "intro");

			this.endingleft	= this.rexUI.add.roundRectangle(480, 540, 800, 800, 25, COLOR_PRIMARY).setAlpha(0.85);

			var leftoutput= `Okay - that’s it team, we’re pulling you out.  Not every mission can be successful and we thank you for your bravery and hard work.

Tough missions can beat the best of us.

If you’re not too battlescarred and would like us to call on you for future missions, please leave your details here.

Thank you again for your participation today.   Good bye and stay safe.

`;

			this.lefttext=this.add.text(960, 900, leftoutput, style);
			this.lefttext.x=480-this.lefttext.displayWidth/2;
			this.lefttext.y=540-this.lefttext.displayHeight/2;

			this.endingright	= this.rexUI.add.roundRectangle(1440, 540, 800, 800, 25, COLOR_PRIMARY).setAlpha(0.85);

			this.feedbackform = this.add.dom(1440, 540).createFromHTML(`<div style="width: 600px;  border-radius: 25px; background-color: #eee; padding: 30px">
			<div id="feedback-form">
			<p  style="font-size: 24px; margin-bottom: 16px; ;font-family: Questrial">If you'd like us to contact you about future missions please enter your details below.</p>
			<p  style="font-size: 24px; margin-bottom: 16px;  font-family: Questrial">Also, if you have any feedback for mission control about your adventure you can leave it here!</p>
					<input type="text" id="emailField" name="emailField" placeholder="Enter your email" style="font-size: 24px; width: 100%; ; font-family: Questrial; padding: 20px"><br /><br />
								<hr style="margin-bottom: 1rem "/>
						<p  style="font-size: 24px; margin-bottom: 16px;  font-family: Questrial">Please rate your experience:</p>
			<div class="rate" style="margin-bottom: 1rem">
	<input type="radio" id="star5" name="rate" value="5" />
	<label for="star5" title="text">5 stars</label>
	<input type="radio" id="star4" name="rate" value="4" />
	<label for="star4" title="text">4 stars</label>
	<input type="radio" id="star3" name="rate" value="3" />
	<label for="star3" title="text">3 stars</label>
	<input type="radio" id="star2" name="rate" value="2" />
	<label for="star2" title="text">2 stars</label>
	<input type="radio" id="star1" name="rate" value="1" />
	<label for="star1" title="text">1 star</label>
</div>
<style>
.rate {
    float: left;
    height: 46px;

}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:40px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
</style>
			<hr style="margin-bottom: 1rem; clear: both "/>
 		<textarea id="feedbackField" type="text" name="feedbackField" placeholder="Any feedback?" style="font-size: 24px; width: 100%; font-family: Questrial; padding: 20px "></textarea><br /><br />
 		<input type="button" name="submitButton" value="Submit" style="font-size: 24px; ; font-family: Questrial; padding: 20px; margin-bottom: 1rem"><br />
		</div>
 		<p id="errorField" style="color: red; font-size: 26px; margin-top: 10px; ; font-family: Questrial"></p>
		<p id="successField" style="color: green; font-size: 26px; margin-top: 10px; ; font-family: Questrial"></p>

 		</div>`);
   this.feedbackform.addListener('click');
this.rating=null;


	this.feedbackform.on('click', function (event) {
		if(event.target.id.substring(0, 4)=="star") {
			this.rating=parseInt(event.target.id.substring(4,5));
			console.log(this.rating);
		}
			if (event.target.name === 'submitButton')
			{
					var inputEmail = document.getElementById("emailField");
						var inputFeedback = document.getElementById("feedbackField");
							var error = document.getElementById("errorField");
							var success = document.getElementById("successField");
							var form = document.getElementById("feedback-form");


					//  Have they entered anything?
					if (inputEmail.value !== "" || inputFeedback.value!=='' || this.rating!==null)
					{



						var xhr = new XMLHttpRequest();
						xhr.open('POST', 'https://www.daveescaperooms.com/feedbackapi');
						xhr.setRequestHeader('Content-Type', 'application/json');
						xhr.onload = function() {
						    if (xhr.status === 200) {
						          success.innerHTML="Thanks for your feedback!";
											form.style.display="none";
						    } else {
									error.innerHTML="There was a problem with your submission :(";
								}
						};
						xhr.send(JSON.stringify({"feedback": {"stars":this.rating, "sale_id":gamecode, "email": inputEmail.value, "feedback": inputFeedback.value}}));

					}
					else
					{

						console.log(error);
						error.innerHTML="Please fill in at least one field";
			}
			}

	}, this);
}
	msToTime(duration) {
  var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60))),
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return minutes + ":" + seconds;
}

}
