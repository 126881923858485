import Phaser from 'phaser'

export default class Endingnovideo extends Phaser.Scene
{
	constructor()
	{
		super({key: "Endingnovideo"});
	}

	preload() {

	}


	create ()
	{

		this.endingdooropen=this.add.image(960, 540, "endingdooropen");
		this.toscene2 = this.add.sprite(1550, 600, 'doorpack', 'toscene2').setInteractive({useHandCursor: true}).setAlpha(0.01);
		this.toscene2.on('pointerdown', function(){
					 this.scene.start("Scene2");
		}, this);
		this.tocredits=this.add.image(1326, 494,'doorpack', "tocredits").setInteractive({useHandCursor: true}).setAlpha(0.01);
		this.tocredits.on('pointerdown', function() {
			if(state.ritafound) {
			 this.scene.start("Credits");
		 } else {
			 sendMessage("You've found the way out, but still need to complete your mission of finding where Rita went!", 5000);
		 }
		}, this);
	}

	update() {
		if(newState) {

			newState=false;
		}
	}
}
