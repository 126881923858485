import Phaser from 'phaser'

export default class Lobby extends Phaser.Scene
{
	constructor()
	{
		super({key: "Lobby"});
	}

	preload() {
		this.load.image('intro', require('../../assets/bg/intro.jpg'));
		this.load.rexWebFont({
				google: {
						families: ['Questrial']
				},
				// testString: undefined,
				// testInterval: 20,
		});
	}


	create ()
	{
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
			const mission = urlParams.get('m');
			if (mission) {
				var missionid=mission;
			} else {
				var missionid="";
			}
				this.add.image(960, 540, "intro");

	     this.lobbyform = this.add.dom(960, 540).createFromHTML(`<div style="width: 600px;  border-radius: 25px; background-color: #eee; padding: 30px">
	<p style="font-size: 26px; margin-top: 10px; ; font-family: Questrial">Your Name</p>
			 <input type="text" id="nameField" name="nameField" placeholder="Enter your name" style="font-size: 32px; width: 100%; ; font-family: Questrial; padding: 20px"><br /><br />

			 		<p style="font-size: 26px; margin-top: 10px; ; font-family: Questrial">Mission Code</p>
			<input id="gameField" type="text" name="gameField" placeholder="Enter your mission code" value="`+missionid+`" style="font-size: 32px; width: 100%; font-family: Questrial; padding: 20px "><br /><br />

			<input type="button" name="playButton" value="Start" style="font-size: 32px; ; font-family: Questrial; padding: 20px"><br />
			<p id="errorField" style="color: red; font-size: 26px; margin-top: 10px; ; font-family: Questrial"></p>

			</div>`);

			var scene=this;

	    this.lobbyform.addListener('click');
			var topscope=this;


	    this.lobbyform.on('click', function (event) {
				console.log(event.target.name);
	        if (event.target.name === 'playButton')
	        {
	            var inputName = document.getElementById("nameField")
							  var inputGame = document.getElementById("gameField")
									var error = document.getElementById("errorField")

	            //  Have they entered anything?
	            if (inputName.value !== '' && inputGame.value!=='')
	            {

							firebase.database().ref(gametitle+"/"+inputGame.value).once('value').then(function(snap) {
								if(snap.val()) {
									topscope.lobbyform.setAlpha(0);
									if(loginPlayer(inputName.value, inputGame.value)){
										console.log("test");
										scene.scene.start("Preloader");
									}

								} else {
									error.innerHTML="Mission not found";
								}
							}, topscope);

	            }
	            else
	            {

								console.log(error);
								error.innerHTML="Please fill in all fields";
	        }
	        }

	    }, this);

	}
}
