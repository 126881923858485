import Phaser from 'phaser'

export default class Scene2f extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2f"});
	}

    create()
    {
			this.add.image(960, 540, 'scene2fbg');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene2c");
			}, this);
			newState=true;
			game.launchAudio("vo03feb", "Diary: February 3rd");

    }

		update() {
			if(newState) {
				this.game.grammoAudio.setVolume("0");
				newState=false;
			}
		}
}
