import Phaser from 'phaser'

export default class Scene2 extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2"});
	}

	preload() {

	}

    create()
    {
			if(state.wardrobeopen) {
				this.wardrobeopen=true;
			} else {
				this.wardrobeopen=false;
			}


			this.add.image(960, 540, 'scene2bg');
			this.warddoor = this.add.sprite(1625, 471, 'warddoor').setAlpha(1);



			this.toscene1 = this.add.sprite(105, 376, 'doorpack', 'toscene1').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.toscene1.on('pointerdown', function(){
						 this.scene.start("Scene1");
			}, this);
			this.toscene3 = this.add.sprite(1535, 831, 'doorpack', 'toscene3').setInteractive({useHandCursor: true}).setAlpha(0);
			this.toscene3.on('pointerdown', function(){
						 this.scene.start("Scene3");
			}, this);

			this.lpdb = this.add.sprite(229, 776, 'doorpack', 'lpdb').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.lpdb.on('pointerdown', function(){
						 this.scene.start("Scene2b");
			}, this);
			this.tophone = this.add.sprite(744, 586, 'doorpack', 'tophone').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.tophone.on('pointerdown', function(){
						 this.scene.start("Scene2d");
			}, this);

			this.tomar1 = this.add.sprite(1713, 472, 'doorpack', 'tomar1').setInteractive({useHandCursor: true}).setAlpha(0);
			this.tomar1.on('pointerdown', function(){
						 this.scene.start("Scene2g");
			}, this);

			this.recordspinwide=this.add.video(194, 699, "recordspinwide").setAlpha(0);

			//grammobuttons

			this.grammored=this.add.sprite(150, 820,'objectpack', "gwreds").setAlpha(0);
			if(state.recorddrop)  {
				this.grammored.setAlpha(1);
			}
			var label;
			var buttonsx=[80, 104, 128, 152, 178, 80, 104, 128, 152, 180, 80, 106, 130, 154, 182, 84, 108, 132, 156, 182, 84, 108, 132, 156, 182];
				var buttonsy=[774, 774, 774, 776, 778, 800, 800, 800, 800, 800, 822, 822, 822, 822, 822, 844, 846, 846, 846, 846, 868, 868, 868,869,868];
				var buttoncount=0;
			for (var i = 1; i < 6; i++) {
				for (var j=1; j<6; j++){
				label="gw"+i+j;
				this[label] = this.add.sprite(buttonsx[buttoncount], buttonsy[buttoncount], "objectpack", label).setAlpha(state["g"+i+j]);
				this[label].name=label;
				this[label].j=j;
				this[label].i=i;
				buttoncount=buttoncount+1;
			}
			}

			this.keydb=this.add.image(1465,496,'doorpack', "keydb").setAlpha(0.01);

			this.wardopenvideo = this.add.video(1625, 471, 'wardopenvideo').setAlpha(0);

			this.grammoopena = this.add.image(352, 800, 'grammoopena').setAlpha(0);
			this.grammoopenb = this.add.image(352, 800, 'grammoopenb').setAlpha(0);
			this.warddooroncreate=state.wardrobeopen;
			newState=true;
    }

		update() {
			if(newState) {
				if(state.wardrobeopen) {
					this.wardrobeopen=true;
				} else {
					this.wardrobeopen=false;
				}
				if(state.recorddrop) {
				this.grammored.setAlpha(1);
				this.recordspinwide.setAlpha(1);
				this.recordspinwide.play(true);
			}
				for (var i = 1; i < 6; i++) {
					for (var j=1; j<6; j++){

					this["gw"+i+j].setAlpha(state["g"+i+j]);
				}
				}

				if(state.wardrobeopen) {
					this.toscene3.setAlpha(0.01);
					this.warddoor.setAlpha(0);
					this.keydb.setAlpha(0);
					this.tomar1.setAlpha(0.01);
				}

				if(state.gcomplete) {
					this.grammoopena.setAlpha(1);
				}

				if(state.gcomplete && state.pickedhaydn) {
					this.grammoopenb.setAlpha(1);
				}

				if(state.wardrobeopen && !this.warddooroncreate) {
					this.wardopenvideo.setAlpha(1);
					var vidref= this.wardopenvideo.play();
					this.wardopenvideo.on('complete', function(video){
						vidref.setAlpha(0);
					});
					this.warddooroncreate=true;
				}



				newState=false;
			}
		}

}
