import Phaser from 'phaser'

export default class Scene3c extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3c"});
	}

    create()
    {
			this.winorgan=this.sound.add('organ');
			this.add.image(960, 540, 'organbg');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene3");
			}, this);
			this.pipesdb = this.add.image(955, 190, 'doorpack','pipedb').setAlpha(0.01);
			this.pipesoncreate=state.pipescompleted;
			var yRows=[176, 363,549,736,923];
			var xRows=[589, 775,961,1148,1336];
			for(var i=0; i<5; i++){
				for(var j=0; j<5; j++) {

					this["p"+i+j]=this.add.sprite(xRows[j], yRows[i], "objectpack",state["p"+i+j]).setInteractive({useHandCursor: true});
					this["p"+i+j].label="p"+i+j;
					this["p"+i+j].on('pointerdown', function() {
						this.scene.nextPipe(this.label);
					})

				}
			}
		if(state["droppedpipe"]==false) {
			this["p02"].setAlpha(0.01);
		}

		newState=true;
    }

		update() {
			if(newState) {
				var pipescompleted=true;
				var correctSequence = {
					p00: "pes",
					p01: "psw",
					p02: "gns",
p03: "pes",
p04: "psw",
p10: "pns",
p11: "pns",
p12: "pne",
p13: "pwn",
p14: "pns",
p20: "pns",
p21: "pne",
p22: "pew",
p23: "psw",
p24: "pns",
p30: "pne",
p31: "pew",
p32: "psw",
p33: "pns",
p34: "pns",
p40: "pes",
p41: "pew",
p42: "pwn",
p43: "pne",
p44: "pwn",
				}
				for(var i=0; i<5; i++){
					for(var j=0; j<5; j++) {
						this["p"+i+j].setTexture("objectpack", state["p"+i+j]);
						if(state["p"+i+j]!=correctSequence["p"+i+j]) {
							pipescompleted=false;
						}
					}
				}
				if(state["droppedpipe"]==true) {
					this["p02"].setAlpha(1);
				}

				if(pipescompleted && !this.pipesoncreate) {
					console.log("Pipes Completed!");
					this.winorgan.play();
					updateState("pipescompleted", true);
						updateState("milestone", "getbulb");
					this.pipesoncreate=true;
				}


				newState=false;
			}

		}
		nextPipe(label) {
			if(state["droppedpipe"] && !state["pipescompleted"]) {
			if(state[label]=="pes") {
				updateState(label, "psw");
				console.log("Updated");
			} else if(state[label]=="psw") {
				updateState(label, "pwn");
			} else if(state[label]=="pwn") {
				updateState(label, "pne");
			} else if(state[label]=="pne") {
				updateState(label, "pes");
			} else if(state[label]=="pew") {
				updateState(label, "pns");
			} else if(state[label]=="pns") {
				updateState(label, "pew");
			} else if(state[label]=="gns") {
				updateState(label, "gew");
			} else if(state[label]=="gew") {
				updateState(label, "gns");
			}
}

		}
}
