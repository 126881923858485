import Phaser from 'phaser'


export default class Scene1blacklight extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene1blacklight"});
	}
	preload(){

//game.launchAmbience("theatre");
//game.launchAmbience("rain");

if(state.droppedbulb && state.gamecomplete) {
	this.scene.start("Endingnovideo");
}

	}



    create()
    {


			this.toscene2 = this.add.sprite(1550, 600, 'doorpack', 'toscene2').setInteractive({useHandCursor: true});
			this.toscene2.on('pointerdown', function(){
						 this.scene.start("Scene2");
			}, this);
			this.add.image(960, 540, 'scene1bg');


			this.todresser = this.add.sprite(275, 509, 'doorpack', 'todresser').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.todresser.on('pointerdown', function(){
						 this.scene.start("Scene1d");
			}, this);

			newState=true;


			this.blacklightbg=this.add.image(960, 540,"blacklightroom").setAlpha(0);



			this.h1=this.add.image(349, 570, "objectpack", "h1").setInteractive().setAlpha(0);
			var h1clicking=false;
		  this.h1g=this.add.image(349, 570, "objectpack","h1g").setAlpha(0);
			this.h1.on('pointerdown', function(event) {
			  h1clicking=true;
				console.log("clicked hand !");
				this.h1g.setAlpha(1);
						updateState("h1/"+useruuid, true);
			}, this);

			this.h1.on('pointerup', function() {

				if(h1clicking) {
							console.log("h1 off");
				deleteState("h1/"+useruuid);
				h1clicking=false;
			}
			}, this);

			this.h1.on('pointerout', function() {

				if(h1clicking) {
					console.log("h1 off");
					deleteState("h1/"+useruuid);
					h1clicking=false;
				}
			}, this);

		this.h2=this.add.image(654, 168, "objectpack","h2").setInteractive().setAlpha(0);;
			this.h2g=this.add.image(654, 168,"objectpack", "h2g").setAlpha(0);
			var h2clicking=false;
			this.h2.on('pointerdown', function() {
					updateState("h2/"+useruuid, true);
				h2clicking=true;
			}, this);

			this.h2.on('pointerup', function() {
				if(h2clicking) {
					deleteState("h2/"+useruuid);

				h2clicking=false;
			}
			}, this);

			this.h2.on('pointerout', function() {
				console.log("pointerout");
				if(h2clicking) {
					deleteState("h2/"+useruuid);
				 h2clicking=false;
		  	}
			}, this);

			this.h3=this.add.image(1144, 52,"objectpack", "h3").setInteractive().setAlpha(0);;
			this.h3g=this.add.image(1144, 52, "objectpack","h3g").setAlpha(0);
			var h3clicking=false;

			this.h3.on('pointerdown', function() {
				h3clicking=true;
				updateState("h3/"+useruuid, true);
			}, this);

			this.h3.on('pointerup', function() {
				if(h3clicking) {
					deleteState("h3/"+useruuid);


				h3clicking=false;
			}
			}, this);

			this.h3.on('pointerout', function() {
				if(h3clicking) {
					deleteState("h3/"+useruuid);
			}
			}, this);

			this.h4=this.add.image(1756, 584,"objectpack", "h4").setInteractive().setAlpha(0);
			var h4clicking=false;
			this.h4g=this.add.image(1756, 584,"objectpack", "h4g").setAlpha(0);
			this.h4.on('pointerdown', function() {
				h4clicking=true;
				updateState("h4/"+useruuid, true);
			}, this);

			this.h4.on('pointerup', function() {
				console.log("clicked up!");
				if(h4clicking) {
				deleteState("h4/"+useruuid);
				h4clicking=false;
			}
			}, this);

			this.h4.on('pointerout', function() {
				if(h4clicking) {
					deleteState("h4/"+useruuid);
				h4clicking=false;
			}
			}, this);

			this.h5=this.add.image(1304, 1024, "objectpack","h5").setInteractive().setAlpha(0);;
			this.h5g=this.add.image(1304, 1024, "objectpack","h5g").setAlpha(0);
			var h5clicking=false;

			this.h5.on('pointerdown', function() {
				h5clicking=true;
					updateState("h5/"+useruuid, true);

			}, this);

			this.h5.on('pointerup', function() {
				if(h5clicking) {
					deleteState("h5/"+useruuid);


				h5clicking=false;
			}
			}, this);

			this.h5.on('pointerout', function() {
			if(h5clicking) {
					deleteState("h5/"+useruuid);


				h5clicking=false;
			}
			}, this);

			this.h6=this.add.image(668, 1024,"objectpack", "h6").setInteractive().setAlpha(0);
			this.h6g=this.add.image(668, 1024,"objectpack", "h6g").setAlpha(0);
			var h6clicking = false;
			this.h6.on('pointerdown', function() {
					updateState("h6/"+useruuid, true);
				h6clicking=true;
			}, this);

			this.h6.on('pointerup', function() {
				if(h6clicking) {
				deleteState("h6/"+useruuid);
				h6clicking=false;
			}
			}, this);

			this.h6.on('pointerout', function() {
				if(h6clicking) {
					deleteState("h6/"+useruuid);
				h6clicking=false;
			}
			}, this);


			//magic videos


			this.anims.create({
		 key: 'ml6loop',
		 frames: this.anims.generateFrameNames('magic1', { prefix: 'ml', start:0, end: 9 }),
		 yoyo: false,
		 framerate:12,
		 repeat: -1
	});

		this.ml1=this.add.sprite(445, 426, 'magic1', 'ml3');
	  this.ml1.anims.play("ml6loop");


		this.anims.create({
	 key: 'ml2loop',
	 frames: this.anims.generateFrameNames('magic1', { prefix: 'ml', start:10, end: 19 }),
	 yoyo: false,
	 framerate:12,
	 repeat: -1
});

	this.ml2=this.add.sprite(888, 223, 'magic1', 'ml10');
	this.ml2.anims.play("ml2loop");

	this.anims.create({
 key: 'ml3loop',
 frames: this.anims.generateFrameNames('magic1', { prefix: 'ml', start:20, end: 29 }),
 yoyo: false,
 framerate:12,
 repeat: -1
});

this.ml3=this.add.sprite(1405, 423, 'magic1', 'ml20');
this.ml3.anims.play("ml3loop");

this.anims.create({
key: 'ml4loop',
frames: this.anims.generateFrameNames('magic1', { prefix: 'ml', start:30, end: 39 }),
yoyo: false,
framerate:12,
repeat: -1
});

this.ml4=this.add.sprite(1482, 719, 'magic1', 'ml30');
this.ml4.anims.play("ml4loop");

this.anims.create({
key: 'ml5loop',
frames: this.anims.generateFrameNames('magic1', { prefix: 'ml', start:40, end: 49 }),
yoyo: false,
framerate:12,
repeat: -1
});

this.ml5=this.add.sprite(1007, 879, 'magic1', 'ml40');
this.ml5.anims.play("ml5loop");


			this.blacklightbg.setAlpha(1);
			this.h1.setAlpha(1);
			this.h2.setAlpha(1);
			this.h3.setAlpha(1);
			this.h4.setAlpha(1);
			this.h5.setAlpha(1);
			this.h6.setAlpha(1);
    }

		update() {

			if (state.magicstage==0) {

				this.ml1.setAlpha(0);
				this.ml2.setAlpha(0);
				this.ml3.setAlpha(0);
				this.ml4.setAlpha(0);
				this.ml5.setAlpha(0);

			}

			if(newState) {
			console.log(state.magicstage);


			if(state.magicstage==0  && (Object.keys(state.h1).length>1 && Object.keys(state.h2).length>1)) {

				updateState("magicstage", 1);
			} else if(state.magicstage==1 && (Object.keys(state.h2).length>1 && Object.keys(state.h3).length>1)) {
				updateState("magicstage", 2);
			} else if(state.magicstage==2 && (Object.keys(state.h3).length>1 && Object.keys(state.h4).length>1)) {
				updateState("magicstage", 3);
			} else if(state.magicstage==3 && (Object.keys(state.h4).length>1 && Object.keys(state.h5).length>1)) {
				updateState("magicstage", 4);
			} else if(state.magicstage==4 && (Object.keys(state.h5).length>1 && Object.keys(state.h6).length>1)) {
				updateState("magicstage", 5);
			} else if(state.magicstage==5 && (Object.keys(state.h6).length>1 && Object.keys(state.h1).length>1)) {
				updateState("gamecomplete", true);
				if(state.ritafound) {
					updateState("milestone", "exit");
				} else {
					updateState("milestone", "findrita");
				}
			}

			if (state.magicstage==0) {
				this.ml1.setAlpha(0);
				this.ml2.setAlpha(0);
				this.ml3.setAlpha(0);
				this.ml4.setAlpha(0);
				this.ml5.setAlpha(0);
			}

			if(state.magicstage>0 ) {
				this.ml1.setAlpha(1);
			}
			if(state.magicstage>1 ) {
				this.ml2.setAlpha(1);
			}
			if(state.magicstage>2) {
				this.ml3.setAlpha(1);
			}

			if(state.magicstage>3) {
				this.ml4.setAlpha(1);
			}
			if(state.magicstage>4 ) {
				this.ml5.setAlpha(1);
			}



			if(state.magicstage==1  && (Object.keys(state.h2).length==1)) {
			updateState("magicstage", 0);
			}

			if(state.magicstage==2  && (Object.keys(state.h3).length==1)) {
				updateState("magicstage", 0);
			}

			if(state.magicstage==3  && (Object.keys(state.h4).length==1)) {
				updateState("magicstage", 0);
			}
			if(state.magicstage==4  && (Object.keys(state.h5).length==1)) {

				updateState("magicstage", 0);
			}
			if(state.magicstage==5  && (Object.keys(state.h6).length==1)) {
					updateState("magicstage", 0);
			}




			if(Object.keys(state.h1).length>1) {
				this.h1g.setAlpha(1);
			} else {
				this.h1g.setAlpha(0);
			}
				if(Object.keys(state.h2).length>1) {
				this.h2g.setAlpha(1);
			} else {
				this.h2g.setAlpha(0);
			}

				if(Object.keys(state.h3).length>1) {
				this.h3g.setAlpha(1);
			} else {
				this.h3g.setAlpha(0);
			}

			if(Object.keys(state.h4).length>1) {
				this.h4g.setAlpha(1);
			} else {
				this.h4g.setAlpha(0);
			}

			if(Object.keys(state.h5).length>1) {
				this.h5g.setAlpha(1);
			} else {
				this.h5g.setAlpha(0);
			}

			if(Object.keys(state.h6).length>1) {
				this.h6g.setAlpha(1);
			} else {
				this.h6g.setAlpha(0);
			}

			if(state.gamecomplete) {
				this.scene.start("Ending");
			}
			console.log("refresh state");
				newState=false;
			}

		}


}
