import Phaser from 'phaser';
import { v4 as uuidv4 } from 'uuid';
import Flash from 'phaser3-rex-plugins/plugins/flash.js';



export default class Ui extends Phaser.Scene
{
	constructor()
	{
		super({key: "Ui"});
	}

	preload() {
		this.load.rexWebFont({
		    google: {
		        families: ['Questrial']
		    },
		    // testString: undefined,
		    // testInterval: 20,
		});
	}

    create()
    {

			    this.ritafoundmessage=state.ritafound;
			    this.extralife=state.extralife;
					this.timedout=state.timedout;

	//  The Text is positioned at 0, 100
	var style = { fontSize: "64px", fill: "#fff", fontFamily: "Questrial" };
	this.countdown = this.add.text(1720, 30, "", style);
	this.gps = this.add.sprite(1820, 160, "objectpack", "gpsred").setInteractive({useHandCursor: true}).setScale(0.8);
	this.exit = this.add.sprite(1820, 260, "objectpack", "exitred").setScale(0.8);
	this.hint = this.add.sprite(1820, 360, "objectpack", "hint").setInteractive({useHandCursor: true}).setScale(0.7).setAlpha(0);

	this.messages = this.add.group();
	this.flash = new Flash(this.countdown, {duration: 1000});
	this.gps.on('pointerdown', function(){
		var checkReturn = game.scene.getScenes(true)[0].scene.key
		 if(checkReturn!="Gps") {
		 afterGps=game.scene.getScenes(true)[0].scene.key
		 var returnScene=this.scene.get(afterGps);
		 returnScene.scene.start("Gps");


		 this.scene.bringToTop();
	   }

	}, this);

	this.hint.on('pointerdown', function(){
			sendHint();
}, this);
		this.game.buildInv(this);

}
		update() {
			if(newState) {
				this.game.buildInv(this);
				if(state.ritafound) {
					this.gps.setTexture("objectpack", "gpsgreen");
				}
				if(state.gamecomplete) {
					this.exit.setTexture("objectpack", "exitgreen");
				}
			}

			if(state.ritafound && !this.ritafoundmessage) {
					sendMessage("MISSION ACCOMPLISHED! You found Rita! It sounds like the Order of the Broken Vase are dangerous.  Best get out of there before you're discovered!", 10000);
					this.ritafoundmessage=true;

			}




			if(!state.extralife && this.extralife) {
				window.sendMessage("I think I can give you just a little more time.  You're almost there!", 10000);
				console.log("Extra Life");
				this.extralife=false;
			}


			if(state.timedout && !this.timedout) {
					sendMessage("It looks like the Order of the Broken Vase are headed your way.  Please hurry!", 10000);
					this.timedout=true;
			}



			if(Date.now()-state.lasthint>60000) {
				this.hint.setAlpha(1);
			} else {
				this.hint.setAlpha(0);
			}

			if(newPlayers) {
				this.game.buildPlayers(this);
			}

			if(this.getTimeRemaining(gameEnd).total>0) {
				this.countdown.setText(this.getTimeRemaining(gameEnd).minutes+":"+(("00"+this.getTimeRemaining(gameEnd).seconds).slice(-2)));
				if(this.getTimeRemaining(gameEnd).minutes<10) {
				this.countdown.setColor("#F7431F");
			} else {
				this.countdown.setColor("#fff");
			}

				if(this.getTimeRemaining(gameEnd).minutes<3) {
					this.flash.start();
			}
		} else {

				if(state.extralife) {

				setClock(10);
				console.log("Extra life!");
				updateState("extralife", false);
			} else {
				 if(!state.timedout) {
				 updateState("timedout", true);

			} else {
				this.countdown.setText("0:00");
				this.countdown.setColor("#F7431F");
				this.flash.start();
			}
		}
	}
}

		postMessage(message, duration) {
			var sceneref=this;
			var messageId = uuidv4();
			this["message_"+messageId]=this.add.text(1920, 968, message, { fontSize: "32px", fill: "#fff", borderRadius: "25px", backgroundColor:"#0a0a0a", fontFamily: "Questrial", padding: {top: 20, bottom: 20, left: 20, right: 20 }, wordWrap: { width: 1000} });
      this["message_"+messageId].x=1920-(sceneref["message_"+messageId].displayWidth);
			this["message_"+messageId].y=1080-((sceneref["message_"+messageId].displayHeight)+10);
			this.messages.getChildren().forEach( function(item) {
				item.y -= this["message_"+messageId].displayHeight+10;
			}, this);


				this.messages.add(this["message_"+messageId]);
			if(duration) {
				setTimeout(function() {
					sceneref.killMessage(messageId);
				}, duration);
			}

			return messageId;
		}

killMessage(messageId) {
var sceneref=this;
	var tween = sceneref.tweens.add({
			targets: sceneref["message_"+messageId],
			alpha: 0,
			ease: 'Power2',
			duration: 500,
			onComplete: function() {

				sceneref.messages.getChildren().forEach( function(item) {
					if(item.y < sceneref["message_"+messageId].y) {
							item.y += this["message_"+messageId].displayHeight+10;

			}
		}, sceneref);
			sceneref.messages.remove(this["message_"+messageId]);
	}
	});

	}


		getTimeRemaining(endtime){
  const total = endtime - Date.parse(new Date());
  const seconds = Math.floor( (total/1000) % 60 );
  const minutes = Math.floor( (total/1000/60));
  const hours = Math.floor( (total/(1000*60*60)) % 24 );
  const days = Math.floor( total/(1000*60*60*24) );

  return {
		total,
    hours,
    minutes,
    seconds
  };
}
}
