import Phaser from 'phaser'

export default class Scene2a extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2a"});
	}



    create()
    {
			console.log(this);
			if(!state.recorddrop) {
			this.add.image(960, 540, 'scene2abgpre');
			} else {
			this.add.image(960, 540, 'scene2abg');
		}
			this.greset=this.add.image(1258, 734, 'objectpack', 'greset').setInteractive({useHandCursor: true});

			this.greset.on('pointerdown', function(){
				if(!state.gcomplete) {
				 this.resetButtons();
			 }
			}, this);
			var gx=781;
			var gy=374;
			var buttons=1;
			var label="";
			var buttonobj

			for (var i = 1; i < 6; i++) {
				for (var j=1; j<6; j++){
				label="g"+i+j;
				console.log(label);
  			this[label] = this.add.sprite(gx, gy, "objectpack", label).setInteractive({useHandCursor: true}).setAlpha(state[label]);
				this[label].name=label;
				this[label].j=j;
				this[label].i=i;
				this[label].on('pointerdown', function(){
					if(!state.gcomplete) {
					this.scene.toggleButton(this);
					if(this.j<5) {
						this.scene.toggleButton(this.scene["g"+this.i+(this.j+1)]);
					}
					if(this.j>1) {
						this.scene.toggleButton(this.scene["g"+this.i+(this.j-1)]);
					}
					if(this.i<5) {
						this.scene.toggleButton(this.scene["g"+(this.i+1)+this.j]);
					}
					if(this.i>1) {
						this.scene.toggleButton(this.scene["g"+(this.i-1)+this.j]);
					}
					var checkButtons = 0;
					for (var i = 1; i < 6; i++) {
						for (var j=1; j<6; j++){
							if(state["g"+i+j]=="1")
							checkButtons+=1;
						}
					}
					if (checkButtons==25) {
						    updateState("milestone","portraits");
								updateState("gcomplete", true);
					}
				}
				});
				gx=gx+87;
				buttons=buttons+1;
			}
			gy=gy+84;
			gx=781;
			}

		this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});

		this.backbutton.on('pointerdown', function(){
			 this.scene.start("Scene2b");
		}, this);

		this.cupic=this.add.image(1550,593,'gcupic').setInteractive({useHandCursor: true}).setAlpha(0);
		this.cunopic=this.add.image(1550,593,'gcunopic').setInteractive({useHandCursor: true}).setAlpha(0);
		this.cupic.on('pointerdown', function() {
			this.scene.start("Scene2c");
		}, this);

		this.cunopic.on('pointerdown', function() {
			this.scene.start("Scene2c");
		}, this);

			newState=true;
    }





		update() {
			if(newState) {

				if(state.recorddrop){
				this.setButtons();
			}
			if(state.resetbuttons){
			this.resetButtons();
			updateState("resetbuttons", false);
		}
		if(state.gcomplete) {
			if(!state.pickedhaydn) {
			this.cupic.setAlpha(1);
		} else {
			this.cunopic.setAlpha(1);
		}
		}
		if(state.recorddrop) {
			this.game.grammoAudio.setVolume("0.3");
		} else {
			this.game.grammoAudio.setVolume("0");
		}

		if(state.gcomplete) {
				this.game.grammoAudio.setRate("0.95");
		} else {
				this.game.grammoAudio.setRate("0.1");
		}
				newState=false;
			}
		}

		toggleButton(button) {
			if(button.alpha==1) {
				updateState(button["name"], "0.01");
			} else {
			updateState(button["name"], "1");

			}
		}
		resetButtons() {
			if(state.recorddrop) {
			for (var i = 1; i < 6; i++) {
				for (var j=1; j<6; j++){
					this["g"+i+j].setAlpha(0.01);
					updateState("g"+i+j, "0.01");
				}
			}
		}
		}
		setButtons() {
			for (var i = 1; i < 6; i++) {
				for (var j=1; j<6; j++){
					this["g"+i+j].setAlpha(state["g"+i+j]);
				}
			}
		}
}
