import Phaser from 'phaser'

export default class Scene2g extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene2g"});
	}

    create()
    {
			this.add.image(960, 540, 'scene2gbg');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene2");
			}, this);
			newState=true;
				game.launchAudio("vo01mar", "Diary: March 1st");
    }

		update() {
			if(newState) {
				newState=false;
			}
		}
}
