import Phaser from 'phaser'

export default class Scene3b extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3b"});
	}

    create()
    {
			this.add.image(960, 540, 'scene3bbg');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});

			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene3");
			}, this);

			this.toritacards = this.add.sprite(788, 271, 'doorpack', 'toritacards').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.toritacards.on('pointerdown', function(){
						 this.scene.start("Scene3g");
			}, this);

			this.toritaletters = this.add.sprite(552, 800, 'doorpack', 'toritaletters').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.toritaletters.on('pointerdown', function(){
						 this.scene.start("Scene3h");
			}, this);

			this.cleanedcoffee=this.add.image(930, 387, "cleanedcoffee").setAlpha(0);
			newState=true;
    }

		update() {
			if(newState) {
	       if(state.clearedcards) {
					 this.cleanedcoffee.setAlpha(1);
				 }
				newState=false;
			}

		}
}
