import Phaser from 'phaser'

export default class Scene1d extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene1d"});
	}

    create()
    {


				this.add.image(960, 540, 'dtablewig');
				this.bg=this.add.image(960, 540, 'dtablebg').setAlpha(0);


			newState=true;


		this.topostcard=this.add.image(584, 846, 'doorpack', 'topostcard').setInteractive({useHandCursor: true}).setAlpha(0.01);
		this.topostcard.on('pointerdown', function(){
			 this.scene.start("Scene1f");
		}, this);

			this.bulbdb2=this.add.image(381,68,'doorpack',"bulbdb2").setAlpha(0.01);



		this.toletter=this.add.image(948, 774, 'doorpack', 'toletter').setInteractive({useHandCursor: true}).setAlpha(0.01);
		this.toletter.on('pointerdown', function(){
			 this.scene.start("Scene1g");
		}, this);
		this.blacklightmirror=this.add.image(960, 540, 'blacklightmirror').setAlpha(0);

		this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
		this.backbutton.on('pointerdown', function(){
			 this.scene.start("Scene1");
		}, this);
		newState=true;
	}


		update() {
			if(newState) {

				if(!state.pickedwig) {
					this.wighb=this.add.image(275, 453, 'doorpack', 'wighb').setInteractive({useHandCursor: true}).setAlpha(0.01);
					this.wighb.on("pointerdown", function(){
						this.game.inventoryAdd(this, "wig");
						updateState("pickedwig", true);
					}, this);
				} else {
					this.bg.setAlpha(1);
				}
				if(state.droppedbulb) {
						this.blacklightmirror.setAlpha(1);
						this.toletter.setAlpha(0);
						this.topostcard.setAlpha(0);
				}
				newState=false;
			}

		}
}
