import Phaser from 'phaser'

export default class Scene3d extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3d"});
	}

    create()
    {
			this.add.image(960, 540, 'organbg2');
			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.organbg2_open=this.add.image(960, 540, "organbg2_open").setAlpha(0);
			this.blacklight=this.add.image(421, 691, "blacklight").setInteractive({useHandCursor: true}).setAlpha(0);
			this.blacklight.on('pointerdown', function() {
				this.game.inventoryAdd(this, "bulb");
				updateState("pickedbulb", true);
				updateState("milestone", "dropbulb");
			}, this);
this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Scene3");
			}, this);
			newState=true;
    }

		update() {
			if(newState) {
				if(state.pipescompleted) {
					this.organbg2_open.setAlpha(1);
					if(!state.pickedbulb) {
						this.blacklight.setAlpha(1);
					} else {
						this.blacklight.setAlpha(0);
					}
				}
				newState=false;
			}

		}
}
