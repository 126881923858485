import Phaser from 'phaser'
import Drag from 'phaser3-rex-plugins/plugins/drag.js';
import { v4 as uuidv4 } from 'uuid';
import { LoaderLoggerPlugin, LoaderTimerPlugin, LoaderBarPlugin, LoaderTextPlugin } from 'phaser-plugin-loader';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';







import Scene1 from './scenes/Scene1'
import Scene1blacklight from './scenes/Scene1blacklight'
import Scene1a from './scenes/Scene1a'
import Scene1b from './scenes/Scene1b'
import Scene1c from './scenes/Scene1c'
import Scene1d from './scenes/Scene1d'
import Scene1e from './scenes/Scene1e'
import Scene1f from './scenes/Scene1f'
import Scene1g from './scenes/Scene1g'
import Scene1h from './scenes/Scene1h'
import Scene1i from './scenes/Scene1i'
import Scene1j from './scenes/Scene1j'
import Scene2 from './scenes/Scene2'
import Scene2a from './scenes/Scene2a'
import Scene2b from './scenes/Scene2b'
import Scene2c from './scenes/Scene2c'
import Scene2d from './scenes/Scene2d'
import Scene2e from './scenes/Scene2e'
import Scene2f from './scenes/Scene2f'
import Scene2g from './scenes/Scene2g'
import Scene2h from './scenes/Scene2h'
import Scene3 from './scenes/Scene3'
import Scene3a from './scenes/Scene3a'
import Scene3b from './scenes/Scene3b'
import Scene3c from './scenes/Scene3c'
import Scene3d from './scenes/Scene3d'
import Scene3e from './scenes/Scene3e'
import Scene3f from './scenes/Scene3f'
import Scene3g from './scenes/Scene3g'
import Scene3h from './scenes/Scene3h'
import Scene3i from './scenes/Scene3i'
import Scene3j from './scenes/Scene3j'
import Scene3k from './scenes/Scene3k'
import Preloader from './scenes/Preloader'
import Lobby from './scenes/Lobby'
import Ui from './scenes/Ui'
import Gps from './scenes/Gps'
import Credits from './scenes/Credits'
import Losscredits from './scenes/Losscredits'
import Ending from './scenes/Ending'
import Endingnovideo from './scenes/Endingnovideo'
import Briefing from './scenes/Briefing'
import Holding from './scenes/Holding'


window.state=null;
window.inv=null;
window.hints=null;
window.hintsstate=null;
window.newState=false;
window.newPlayers=false;
window.loaded=false;
window.testmode=false;
window.players = null;
window.gametitle="escapezoom1";
window.gameEnd=null;
window.audioPlaying=false;
window.audioObjPlaying=null;
window.audioInstancePlaying=null;
window.audioMessage=null;
window.musicPlaying=null;
window.playedAudio=[];
window.afterGps="";




//Firebase config

var dbconfig = {
  apiKey: "AIzaSyBtjzQJirBudVAdug37N-O78_cFpXetNzQ",
  authDomain: "escaperoom-649b9.firebaseapp.com",
  databaseURL: "https://escaperoom-649b9.firebaseio.com/",
};
firebase.initializeApp(dbconfig);




// Presence in game




var username = "";



var useruuid = uuidv4();
window.useruuid=useruuid;


// Create a reference to this user's specific status node.
// This is where we will store data about being online/offline.
var updateStatus= function(status) {
var xhr = new XMLHttpRequest();
xhr.open('POST', 'https://www.daveescaperooms.com/statusapi');
xhr.setRequestHeader('Content-Type', 'application/json');
xhr.onreadystatechange = function() {
    if (xhr.readyState == XMLHttpRequest.DONE) {
        console.log(xhr.responseText);
    }
}
xhr.send(JSON.stringify({"status": { "sale_id":gamecode, "status": status}}));
console.log("Sending status update" +status);

}

var loginPlayer= function(name, gamecode) {
  console.log("Log in player!");
  window.gamecode=gamecode;
  window.hintsloaded=false;
  if(!testmode) {
   const stateObject = firebase.database().ref().child(gametitle+'/'+gamecode+"/state");
   const hintObject = firebase.database().ref().child(gametitle+'/'+gamecode+"/hints");
   hintObject.on("value" , function(snap) {
     hintsstate=snap.val();
     if(hintsloaded) {
         sendMessage(hintsstate[hintsstate.length-1].text, 10000);
     }
     hintsloaded=true;
   });


  stateObject.on("value", function(snap) {
    if (snap.val()==null)  {
      var startState={
  			pickedlp: false,
  			safedial1: 1,
  			safedial2: 1,
  			safedial3: 1,
  			safestate: 0,
        recorddrop: false,
        resetbuttons:false,
        pickedwig:false,
        gcomplete: false,
        pickedhaydn:false,
        droppedhaydn:false,
        portraitorder:["moz", "sho", "hay", "bra", "bee" ],
        portraitscomplete:false,
        keypadcode: ["holder"],
        lightsequencestarted: 0,
        trophiesopen: false,
        pickedpipe: false,
        playlights:true,
        g11: 0,
        g12: 0,
        g13: 0,
        g14: 0,
        g15: 0,
        g21: 0,
        g22: 0,
        g23: 0,
        g24: 0,
        g25: 0,
        g31: 0,
        g32: 0,
        g33: 0,
        g34: 0,
        g35: 0,
        g41: 0,
        g42: 0,
        g43: 0,
        g44: 0,
        g45: 0,
        g51: 0,
        g52: 0,
        g53: 0,
        g54: 0,
        g55: 0,
        p00: "psw",
        p01: "pwn",
        p02: "gns",
        p03: "psw",
        p04: "pwn",
        p10: "pew",
        p11: "pew",
        p12: "pes",
        p13: "pwn",
        p14: "pew",
        p20: "pew",
        p21: "pne",
        p22: "pns",
        p23: "psw",
        p24: "pew",
        p30: "pes",
        p31: "pew",
        p32: "psw",
        p33: "pns",
        p34: "pew",
        p40: "psw",
        p41: "pns",
        p42: "pne",
        p43: "pes",
        p44: "pne",
        magicstage:0,
        h1:{
          holder: "holder"
        },
        h2:{
          holder: "holder"
        },
        h3:{
          holder: "holder"
        },
        h4:{
          holder: "holder"
        },
        h5:{
          holder: "holder"
        },
        h6:{
          holder: "holder"
        },
        wardrobeopen: false,
        jbslider1open:false,
        jbslider2open:false,
        jbslider3open:false,
        jbopen:false,
        jbslider1x:908,
        jbslider1y:248,
        jbslider2x:1071,
        jbslider2y:295,
        jbslider3x:903,
        jbslider3y:345,
        basementlights: 0,
        lightsequence: ["holder"],
        lightson: false,
        clearedcards:false,
        droppedpipe:false,
        droppedbulb: false,
        gamecomplete: false,
        endtime:0,
        gamestarted: false,
        voicesoff: true,
        ritafound: false,
        lasthint:0,
        hintcount:0,
        milestone: "safefind",
        briefing: 0,
        esmareldaCalled:false,
        extralife: true,
        gamelost: false,
        timedout: false
  		};
      firebase.database().ref().child(gametitle+'/'+gamecode+"/state").set(startState);
      newState=true;
        console.log("set start state");
    } else {

  	newState=true;
  	state=snap.val();




  }
  });
}

   const invObject = firebase.database().ref().child(gametitle+'/'+gamecode+"/inv");
     invObject.on("value", function(snap) {
       if(snap.val()==null) {
           var startInv= {
               inventory: ["duck"]
             };
         firebase.database().ref().child(gametitle+'/'+gamecode+"/inv").set(startInv);
     } else {
       inv=snap.val();
     }
   });

var userStatusDatabaseRef = firebase.database().ref(gametitle+'/'+gamecode+"/players/" + useruuid);
window.setInterval(function(){
firebase.database().ref(gametitle+'/'+gamecode+"/players/" + useruuid+"/pointer/x").set(game.input.activePointer.x);
firebase.database().ref(gametitle+'/'+gamecode+"/players/" + useruuid+"/pointer/y").set(game.input.activePointer.y);
if(game.scene.getScenes(true)[0] && game.scene.getScenes(true)[0].scene.key !==null && game.scene.getScenes(true)[0].scene.key !=="Lobby" && game.scene.getScenes(true)[0].scene.key!=="Preloader" && game.scene.getScenes(true)[0].scene.key!="Ui") {
firebase.database().ref(gametitle+'/'+gamecode+"/players/" + useruuid+"/pointer/currentscene").set(game.scene.getScenes(true)[0].scene.key);
}
}, 500);

// We'll create two constants which we will write to
// the Realtime database when this device is offline
// or online.
var isOfflineForDatabase = {
    state: 'offline',
    name: name,
    last_changed: firebase.database.ServerValue.TIMESTAMP,
};

var isOnlineForDatabase = {
    state: 'online',
    name: name,
    last_changed: firebase.database.ServerValue.TIMESTAMP,
    created: Date.parse(new Date()),
    pointer: {
      x: 0,
      y: 0
    }
};



// Create a reference to the special '.info/connected' path in
// Realtime Database. This path returns `true` when connected
// and `false` when disconnected.
firebase.database().ref('.info/connected').on('value', function(snapshot) {
    // If we're not currently connected, don't do anything.
    if (snapshot.val() == false) {
        return;
    };
    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
        userStatusDatabaseRef.set(isOnlineForDatabase);
    });
});

// Handle player updates


const dbPlayerObject = firebase.database().ref().child(gametitle+'/'+gamecode+"/players");
dbPlayerObject.on("value", function(snap) {
	players=snap.val();
  newPlayers=true;
});

const dbTimerObject = firebase.database().ref().child(gametitle+'/'+gamecode+"/timer");
dbTimerObject.on("value", function(snap) {
  gameEnd=snap.val();
});



return true;


}


// End presence

//Set Clock

var setClock = function (minutes) {

  firebase.database().ref().child(gametitle+'/'+gamecode+"/timer").set(Date.now()+(minutes*60*1000));
  updateState("gamestarted", true);

}

var toggleVoices = function () {
  updateState("voicesoff", !state.voicesoff);
}

var createGame= function (gamename) {
  firebase.database().ref().child(gametitle+"/"+gamename).set({
    players: 0,
  });
}

var sendHint= function() {

  var whichhint = 0;
  if (hintsstate && hintsstate[hintsstate.length-1]["milestone"]==state.milestone) {
    whichhint=hintsstate[hintsstate.length-1]["whichhint"]+1;
  }
if (typeof hints[state.milestone][whichhint]=="undefined"){
  sendMessage("There are no more hints right now.  Here is the last one again:", 10000);
  sendMessage(hintsstate[hintsstate.length-1].text, 10000);
  updateState("lasthint", Date.now());
} else {

  firebase.database().ref().child(gametitle+'/'+gamecode+"/hints/"+(state.hintcount+1)).set( { updated: Date.now(), milestone: state.milestone, whichhint: whichhint, text: hints[state.milestone][whichhint]});
  updateState("lasthint", Date.now());
  updateState("hintcount", state.hintcount+1);
}
};

// Represent Players on screen

var buildPlayers = function (scene) {


  Object.entries(players).forEach(val => {

    if(val[1].state=="online") {
      if(scene["player_"+val[0]]) {
        scene["player_"+val[0]].setAlpha(1);
        scene["player_"+val[0]].setDepth(1);

    var tween = scene.tweens.add({
        targets: scene["player_"+val[0]],
        x: val[1].pointer.x,
        y: val[1].pointer.y,
        ease: 'Power2',
        duration: 300
    });

    var tweenname = scene.tweens.add({
        targets: scene["player_"+val[0]+"_name"],
        x: val[1].pointer.x+10,
        y: val[1].pointer.y+10,
        ease: 'Power2',
        duration: 300
    });

    if(game.scene.getScenes(true)[0]) {
    if(val[1].pointer.currentscene==game.scene.getScenes(true)[0].scene.key) {
      scene["player_"+val[0]+"_name"].setAlpha(1);
      scene["player_"+val[0]].setAlpha(1);
    } else {
      scene["player_"+val[0]+"_name"].setAlpha(0);
      scene["player_"+val[0]].setAlpha(0);
    }
  }


      } else {
     if(val[0]!=useruuid && val[1].name!="gameadmin") {
    if(val[1].pointer.x ) {
 scene["player_"+val[0]]= scene.add.sprite(val[1].pointer.x, val[1].pointer.y, "crosshairs").setDepth(1);
 var style = { font: "bold 20px Gill Sans", fill: "#fff", backgroundColor:"#0a0a0a", padding: {top: 10, bottom: 10, left: 10, right: 10 }};
   scene["player_"+val[0]+"_name"]= scene.add.text((val[1].pointer.x+10), (val[1].pointer.y+10), val[1].name, style).setDepth(1);
  if(val[1].created<Date.parse(new Date())) {
  sendMessage(val[1].name+ " has connected", 10000);
}
}
}
}
} else {
  if(scene["player_"+val[0]] && scene["player_"+val[0]].alpha==1) {
    scene["player_"+val[0]].setAlpha(0);
    scene["player_"+val[0]+"_name"].setAlpha(0);
    sendMessage(val[1].name+ " has disconnected", 10000);
  }
}
}
);
}

var buildInv = function (scene) {
  var x=75;
  if(scene.inventory) {
    if(scene.inventory.children) {
    scene.inventory.clear(true, true);
  }
    scene.inventory.destroy()
  }
  scene.inventorylist=[];
  scene.inventory=scene.add.group();
  inv.inventory.forEach((element) => {
        var isDragging = false;
        scene["inventorylist"]["inv_"+element]= scene.add.sprite(x, 1005, 'objectpack', element).setInteractive({useHandCursor: true} ).setDepth(1);
        scene.inventory.add(scene["inventorylist"]["inv_"+element]);
        new Drag(scene["inventorylist"]["inv_"+element], {});
        scene["inventorylist"]["inv_"+element].on('dragend', function(e) {
          checkCollisions(scene);
          scene.game.buildInv(scene);
        }, scene);
        x=x+160;
      });
  };

var inventoryAdd = function(scene, item) {
  if(!inv.inventory.includes(item)) {
  inv.inventory.push(item);
firebase.database().ref().child(gametitle+'/'+gamecode+"/inv").set(inv);
  scene.game.buildInv(scene);
}
newState=true;
}

var launchMusic = function(cue) {
  this.sound.pauseOnBlur= false;
  if(cue!=musicPlaying) {
    musicPlaying=cue;
    var musicfile = game.sound.add(cue);
    musicfile.setLoop(true);
    musicfile.setVolume("0.2");
    musicfile.play();
  }
}

var launchAmbience = function(cue) {
  this.sound.pauseOnBlur=false;
  var musicfile = game.sound.add(cue);
  musicfile.setLoop(true);
  musicfile.setVolume("0.1");
  musicfile.play();
}

var grammoOn=false;
var grammoAudioStart= function() {
  if(!grammoOn){
  game.grammoAudio = game.sound.add("classics", {loop: true, volume: 0});
  game.grammoAudio.play();
}
grammoOn=true;
}

var grammoAudioStop= function() {
game.grammoAudio.setVolume(0);

}

var launchAudio = function (audioobj, message) {
  this.sound.pauseOnBlur = false;
  if(audioObjPlaying==audioobj || state.voicesoff || playedAudio.includes(audioobj) ) {

  } else {
    var audioinstance = uuidv4();
    var messageinstance;
    audioPlaying=true;
   var uiscene=game.scene.getScene("Ui");
    var audiofile =game.sound.add(audioobj);
    audiofile.play();
    console.log(audiofile);
    audioObjPlaying=audioobj;
    audioInstancePlaying=audioinstance;
    messageinstance=sendMessage("Now playing: " + message + " (click to stop)");
    audiofile.once('complete', function(audio){
      console.log(messageinstance);
      uiscene.killMessage(messageinstance);
      audioPlaying=false;
      audioObjPlaying=null;
    });

    uiscene["message_"+messageinstance].setInteractive({useHandCursor: true});
    uiscene["message_"+messageinstance].on("pointerdown", function(){
      audiofile.stop();
      audioPlaying=false;
      audioObjPlaying=null;
      uiscene.killMessage(messageinstance);
    }, this);
    playedAudio.push(audioobj);
  }
}



var inventoryRemove = function(scene, item) {
  var index = inv.inventory.indexOf(item);
  if (index !== -1) inv.inventory.splice(index, 1);
  firebase.database().ref().child(gametitle+'/'+gamecode+"/inv").set(inv);
  scene.game.buildInv(scene);
  newState=true;
}

var checkOverlap = function (scene, spriteA, spriteB) {
  console.log(spriteA);
  if(spriteA && spriteB) {
    var boundsA = spriteA.getBounds();
    var boundsB = spriteB.getBounds();
    return Phaser.Geom.Intersects.RectangleToRectangle(boundsA, boundsB);
  } else {
    return false;
  }
}




var checkCollisions = function (scene) {
  var scene2 = scene.scene.get("Scene2");
  var scene2b=scene.scene.get("Scene2b")

  console.log("check collisions");
  if(checkOverlap(scene, scene["inventorylist"]["inv_lp"], scene2.lpdb) || checkOverlap(scene, scene["inventorylist"]["inv_lp"], scene2b.recordhb)&& game.scene.getScenes(true)[0].scene.key=="Scene2b") {
    inventoryRemove(scene, "lp");
    updateState("milestone", "grammophone");
    updateState("recorddrop", true);
    updateState("resetbuttons", true);
    newState=true;
  }
var scene1c=scene.scene.get("Scene1c")
  if(checkOverlap(scene, scene["inventorylist"]["inv_haydn"], scene1c.portraithb) && game.scene.getScenes(true)[0].scene.key=="Scene1c") {
      console.log("Drop haydn");
    inventoryRemove(scene, "haydn");
    updateState("droppedhaydn", true);
    updateState("milestone", "portraitsorder");
    newState=true;
  }
var scene3b=scene.scene.get("Scene3b");
var scene3g=scene.scene.get("Scene3g");
  if(checkOverlap(scene, scene["inventorylist"]["inv_wig"], scene3g.wighb) && game.scene.getScenes(true)[0].scene.key=="Scene3g") {
    updateState("clearedcards", true);
    inventoryRemove(scene, "wig");
    updateState("milestone", "trophycase");
    console.log("wighb");
    newState=true;
  }

  if(checkOverlap(scene, scene["inventorylist"]["inv_wig"], scene3b.toritacards) && game.scene.getScenes(true)[0].scene.key=="Scene3b") {
    updateState("clearedcards", true);
    inventoryRemove(scene, "wig");
    updateState("milestone", "trophycase");
    console.log("wigtoritacards");
    newState=true;
  }

var scene3c = scene.scene.get("Scene3c");
if(checkOverlap(scene, scene["inventorylist"]["inv_pipe"], scene3c.pipesdb)&& game.scene.getScenes(true)[0].scene.key=="Scene3c") {
    console.log("Drop pipe");
  updateState("droppedpipe", true);
  	updateState("milestone", "setpipes");
  inventoryRemove(scene, "pipe");
  newState=true;
}
console.log(scene);
if(checkOverlap(scene, scene["inventorylist"]["inv_key"], scene2.keydb) && game.scene.getScenes(true)[0].scene.key=="Scene2") {
  console.log("Drop key");
  updateState("wardrobeopen", true);
  updateState("milestone", "baselightson");
  inventoryRemove(scene, "key");
  newState=true;
}

var scene1 = scene.scene.get("Scene1");
if(checkOverlap(scene, scene["inventorylist"]["inv_bulb"], scene1.bulbdb) && game.scene.getScenes(true)[0].scene.key=="Scene1") {

  updateState("droppedbulb", true);
  inventoryRemove(scene, "bulb");
  updateState("milestone", "joinhands");
  newState=true;
}

var scene1d = scene.scene.get("Scene1d");
if(checkOverlap(scene, scene["inventorylist"]["inv_bulb"], scene1d.bulbdb2) && game.scene.getScenes(true)[0].scene.key=="Scene1d") {
  updateState("droppedbulb", true);
  inventoryRemove(scene, "bulb");
    updateState("milestone", "joinhands");
  newState=true;
}

};

var sendMessage = function(message, duration) {
  var messageId = game.scene.getScene("Ui").postMessage(message, duration);
  return messageId;
}



var removeMessage = function(messageId) {

}

if(testmode || !testmode) {
  var plugins={
    global: [{
        key: 'rexWebFontLoader',
        plugin: WebFontLoaderPlugin,
        start: true
    },
    // ...
  ],
  scene: [{
         key: 'rexUI',
         plugin: RexUIPlugin,
         mapping: 'rexUI'
     },
     // ...
     ]
  }
} else {
  var plugins={};
}


const config = {
	type: Phaser.CANVAS,
  loaderTimeout: 30,
  antialias:true,
  enableDebug: false,
  multiTexture: false,
  title: "Rita's Cult Following",
   url: 'https://www.daveescaperooms.com',
   version: '1.0.0',

	scale: {
			mode: Phaser.Scale.FIT,
			parent: 'phaser-example',
			autoCenter: Phaser.Scale.CENTER_BOTH,
			width: 1920,
			height: 1080
	},
  fps: {
    target: 30,
    forceSetTimeOut: true
},
  loader: {
    maxParallelDownloads: 1,
  },
  dom: {
    createContainer: true
},
plugins: plugins,

	scene: [Lobby, Preloader, Briefing, Holding, Scene1, Scene1blacklight , Scene1a, Scene1b, Scene1c, Scene1d, Scene1e, Scene1f, Scene1g, Scene1h, Scene1i, Scene1j, Scene2, Scene2a, Scene2b, Scene2c, Scene2d, Scene2e, Scene2f, Scene2g, Scene2h, Scene3, Scene3a, Scene3b, Scene3c, Scene3d, Scene3e, Scene3f, Scene3g, Scene3h, Scene3i, Scene3j, Scene3k, Ending, Endingnovideo,  Ui,  Gps, Credits, Losscredits]
}

//


// For building/testing we can use a fixed state, which only works locally, but can be set a certain way to test a certain part of the game.




// Otherwise we will use a shared game state in firebase



	if(!loaded) {
	var game = new Phaser.Game(config);
  game.buildInv = buildInv;
  game.buildPlayers = buildPlayers;
  game.checkOverlap=checkOverlap;
  game.inventoryRemove=inventoryRemove;
  game.inventoryAdd=inventoryAdd;
  game.checkCollisions=checkCollisions;
  game.sendMessage=sendMessage;
  game.removeMessage=removeMessage;
  game.launchAudio=launchAudio;
  game.launchMusic=launchMusic;
  game.launchAmbience=launchAmbience;
  game.grammoAudioStart=grammoAudioStart;
  game.grammoAudioStop=grammoAudioStop;

  game.registry.setValue("state", state);
  game.scene.start('L');
  window.game=game;
  loaded=true;

}

// Handle timer


// Utility Global Functions (added to global scope)

var updateState = function(element, thestate) {
	if(testmode) {
		state[element]=thestate;
		newState=true;
	} else {
	firebase.database().ref(gametitle+'/'+gamecode+"/state/"+element).set(thestate);
}
}



var deleteState = function(element, thestate) {
	if(testmode) {
		state[element]=null;
		newState=true;
	} else {
	firebase.database().ref(gametitle+'/'+gamecode+"/state/"+element).remove();
}
}
window.updateState=updateState;
window.deleteState=deleteState;
window.loginPlayer=loginPlayer;
window.updateStatus=updateStatus;
window.setClock = setClock;
window.toggleVoices = toggleVoices;
window.createGame = createGame;
window.sendHint=sendHint;
window.sendMessage=sendMessage;
window.hints = {
  "safefind": {
    0: "Let's have a good look around, clicking on objects can reveal interesting things!",
    1: "Actors are known to be very vain, perhaps that's important?",
    2: "Click on the portrait in the dressing room!"
  },
  "safeopen": {
    0: "Got to get this safe open somehow, maybe there's a clue somewhere",
    1: "Have a look at Rita's letter on the dressing table",
    2: "The number of flowers in the vase match the safe combination!"
  },
  "lpdrop": {
    0: "We need to take this record and put it somewhere, did you know there's another room to the east?",
    1: "Where might we use an old LP?",
    2: "Drag and drop the LP on to the grammophone in the pink room"
  },
  "grammophone": {
    0: "I think we need to turn all the lights green on this grammophone",
    1: "Maybe the plaque on the side of the grammophone is a clue?",
    2: "Take a look at the postcard on Rita's dresser from Luis, his kisses are a key",
    3: "To make all the buttons on the grammophone go green we need to press only the buttons that correspond to the 5x5 grid of kisses from Luis' postcard on Rita's dresser. Don't forget to reset the buttons first!"
  },
  "portraits": {
    0: "Where might this portrait go that we found?",
    1: "Any other composer portraits anywhere?",
    2: "The portrait goes along with the rest on the shelf in the dressing room."
  },
  "portraitsorder": {
    0: "We need to put these portraits in order!",
    1: "The presenter on the LP that's playing on the grammophone talks about Haydn, one of the composers up here.",
    2: "We need to put the portraits in order of how many symphonies they wrote from lowest to highest"
  },
  "openjewels": {
    0: "There's got to be a way to open this jewellery box",
    1: "It looks like those sliders on the top of the box move",
    2: "We need to work together and pull all the sliders at once. You need at least three connected devices to open the box, each person must pull each slider"
  },
  "openwardrobe": {
    0: "This key must fit somewhere!",
    1: "There are some symbols on the jewelry box we opened, maybe they're a clue!",
    2: "The key opens the wardrobe in the pink room"
  },
  "baselightson": {
    0: "Dark down here in the basement, we'll need some light to see!",
    1: "Pressing the button marked START on the control panel in the basement must do something?!",
    2: "Press the start button on the control panel and follow the pattern of lights on the dressing room mirror.  This is the code to enter on the panel's keypad"
  },
  "cleancoffee": {
    0: "There are some business cards on the table in the basement, maybe they're useful",
    1: "We can use the telephone in the pink room to make calls",
    2: "One card isn't visible at the moment, we'll need to mop up that coffee, but how?!",
    3: "We'll need to mop up the coffee on the table with Rita's wig."
  },
  "trophycase": {
    0: "We might need some spiritual guidance to progress further here",
    1: "Listen carefully to the message from Madame Esmarelda!",
    2: "Madame Esmarelda tells you to 'keep your feet firmly on the Earth and add to that a desire to reach for the stars'. What could she be referring to?",
    3: "We can open the trophy cabinet by adding the year the trophy with the Earth and the trophy with stars were awarded.",
    4: "Type in 4015 in to the trophy case keypad!"
  },
  "addpipe": {
    0: "This pipe award we found might be useful somewhere",
    1: "Is there a space in the organ pipes anywhere?",
    2: "We can fit the pipe right at the top of the organ pipes, filling the gap there."
  },
  "setpipes": {
    0: "Seems like we can move the organ pipes now!",
    1: "How could we get the organ to turn on?",
    2: "We need to connect a path from the top to the bottom of the organ using all the pipes"
  },
  "getbulb": {
    0: "The organ turned on! Did anything happen?",
    1: "A compartment opened up on the left of the organ keyboard",
  },
  "dropbulb": {
    0: "We need to find a place to use this bulb we found",
    1: "Maybe there's a place we can plug it in?",
    2: "There's a bulb missing on the dressing room mirror, let's plug the bulb in there."
  },
  "joinhands": {
    0: "There seems to be some strange writing on the floor?  What can it mean?",
    1: "We need to find a way to join the handprints on the wall.  They seem to light up when we click them",
    2: "We need to work together to create a circle of magic around the room",
    3: "You need to work as a team to complete the puzzle.  One person must click and hold the first hand to the right on the mirror, then another must click the next hand above and to the right.  Only the last hand in the chain must be held, other players are free to move and continue the loop going clockwise around the room."
  },
  "findrita": {
    0: "You've found your way out of the room, but still need to enter Rita's location in to the GPS!  Is there anything you've found that can help you figure out where she's gone?",
    1: "Seems like Rita rushed to leave after discovering something on the map on the basement noticeboard",
    2: "Follow the path described in the poem on the noticeboard - Ben Nevis is the tallest mountain in the UK if that helps?",
    3: "She's gone to Fort William - enter that into the GPS and get the flip out of there!"
  },
  "exit": {
    0: "You've done it! Just get out of the room you mad fools - don't hang about the Order are no doubt closing in!"
  }
};
