import Phaser from 'phaser'

const COLOR_PRIMARY = 0x4e342e;
const COLOR_LIGHT = 0x7b5e57;
const COLOR_DARK = 0x260e04;
const COLOR_RED= 0xd43b3b;

export default class Holding extends Phaser.Scene
{
	constructor()
	{
		super({key: "Holding"});
	}

	preload() {

		if(state.gamestarted) {
			this.scene.launch("Scene1");
			this.scene.start("Ui");
		}

		this.load.rexWebFont({
				google: {
						families: ['Questrial']
				},
				// testString: undefined,
				// testInterval: 20,
		});
	}


	create ()
	{

		 this.add.image(960, 540, "van");
		  var style = { fontSize: "64px", fill: "#fff", fontFamily: "Questrial"}


			this.countdown = this.add.text(1720, 30, "60:00", style);


			this.gps = this.add.sprite(1820, 160, "objectpack", "gpsred").setScale(0.8);


			this.exit = this.add.sprite(1820, 260, "objectpack", "exitred").setScale(0.8);


			 this.hint = this.add.sprite(1820, 360, "objectpack", "hint").setScale(0.7).setAlpha(1);



				this.duck = this.add.sprite(75, 1005, 'objectpack', "duck");
style = { fontSize: "32px", fill: "#fff", fontFamily: "Questrial", textAlign: "center"}

				this.playerlist	= this.rexUI.add.roundRectangle(960, 540, 800, 500, 25, COLOR_PRIMARY).setAlpha(0.8);

				this.waitingtext=this.add.text(960, 900, "Ready to start: ", style);
				this.waitingtext.x=960-this.waitingtext.displayWidth/2;
				this.waitingtext.y=540-this.waitingtext.displayHeight/2;
style = { fontSize: "32px", fill: "#fff", fontFamily: "Questrial"}
				this.letsgo = this.rexUI.add.roundRectangle(960, 850, 800, 100, 25, COLOR_PRIMARY).setInteractive({useHandCursor: true}).setAlpha(0.8);
				this.letsgotext=this.add.text(960, 900, "", style);
				this.letsgotext.x=960-this.letsgotext.displayWidth/2;
				this.letsgotext.y=850-this.letsgotext.displayHeight/2;

				this.letsgo2 = this.rexUI.add.roundRectangle(960, 850, 800, 100, 25, COLOR_RED).setInteractive({useHandCursor: true}).setAlpha(0);
				this.letsgotext2=this.add.text(960, 900, "Are you sure?  There's no turning back!", style).setAlpha(0);
				this.letsgotext2.x=960-this.letsgotext2.displayWidth/2;
				this.letsgotext2.y=850-this.letsgotext2.displayHeight/2;

			this.backbutton=this.add.image(110, 115, 'objectpack','backbutton2').setInteractive({useHandCursor: true});
			this.backbutton.on('pointerdown', function(){
				 this.scene.start("Briefing");
			}, this);

			this.letsgo.on('pointerdown', function(){
				 this.letsgo.setAlpha(0);
				 this.letsgotext.setAlpha(0);
				 this.letsgo2.setAlpha(1);
				 this.letsgotext2.setAlpha(1);
			}, this);

			this.letsgo2.on('pointerdown', function(){
				updateStatus("game_started");
				setClock(60);
			}, this);


	}

	update() {
		if(state.gamestarted) {
			this.scene.launch("Scene1");
			this.scene.start("Ui");
		}
var waitingplayers = "Please wait for all team members to arrive:\n\n";
var totalplayers=0;
		Object.entries(players).forEach(val => {

			if(val[1].state=="online" && val[1]["pointer"]["currentscene"]=="Holding") {
				if(waitingplayers=="Please wait for all team members to arrive:\n\n") {
					waitingplayers+=val[1].name;
				} else {
					waitingplayers+="\n"+val[1].name;
				}
				totalplayers=totalplayers+1;
	}
});
if(totalplayers<3) {
	if (totalplayers==2) {
			this.letsgotext.setText("One more connected device required");

	}
	if (totalplayers==1) {
		this.letsgotext.setText("Two more connected devices required");
	}
} else {
	this.letsgotext.setText("We're all here!  Let's do this.");
}
this.letsgotext.x=960-this.letsgotext.displayWidth/2;
this.letsgotext.y=850-this.letsgotext.displayHeight/2;

this.waitingtext.setText(waitingplayers);
this.waitingtext.x=960-this.waitingtext.displayWidth/2;
this.waitingtext.y=540-this.waitingtext.displayHeight/2;





	}
}
