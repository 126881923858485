// Scene 3 wide

import Phaser from 'phaser'

export default class Scene3 extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3"});
	}

    create()
    {
			this.add.image(960, 540, 'scene3bg');
			this.backup = this.add.sprite(176, 494, 'doorpack','backtoscene2').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.backup.on('pointerdown', function(){
						 this.scene.start("Scene2");
			}, this);
			newState=true;
			this.tobox = this.add.sprite(228, 173, 'doorpack', 'tobox').setInteractive({useHandCursor: true}).setAlpha(0.01);
			this.tobox.on('pointerdown', function(){
						 this.scene.start("Scene3a");
			}, this);
			newState=true;

			this.totable = this.add.sprite(531, 793, 'doorpack', 'tobtable').setInteractive({useHandCursor: true}).setAlpha(0);
			this.totable.on('pointerdown', function(){
						 this.scene.start("Scene3b");
			}, this);

			this.topipes = this.add.sprite(1060, 227, 'doorpack', 'topipes').setInteractive({useHandCursor: true}).setAlpha(0);
			this.topipes.on('pointerdown', function(){
						 this.scene.start("Scene3c");
			}, this);

			this.toorgan = this.add.sprite(995, 720, 'doorpack', 'toorgan').setInteractive({useHandCursor: true}).setAlpha(0);
			this.toorgan.on('pointerdown', function(){
						 this.scene.start("Scene3d");
			}, this);
			this.tosarc = this.add.sprite(1588, 830, 'doorpack', 'tosarc').setInteractive({useHandCursor: true}).setAlpha(0);
			this.tosarc.on('pointerdown', function(){
						 this.scene.start("Scene3e");
			}, this);

			this.tonotices = this.add.sprite(488, 574, 'doorpack', 'tonoticeboard').setInteractive({useHandCursor: true}).setAlpha(0);
			this.tonotices.on('pointerdown', function(){
						 this.scene.start("Scene3f");
			}, this);

			this.darkroom=this.add.image(960, 540, "scene3darkbg").setAlpha(0);

			var xRows=[863, 916, 968, 1016, 1069, 857, 910, 962, 1010, 1063, 850, 903, 955, 1003, 1056, 844, 897, 949, 997, 1050, 837, 890, 942, 990, 1043];
				var yRows=[435, 436, 437, 438, 439, 486, 487, 488, 489, 490, 537, 538, 539, 540, 541, 588, 589, 590, 591, 592, 639, 640, 641, 642, 643];
			var counter=0;
			for(var i=0; i<5; i++){
				for(var j=0; j<5; j++) {
					this["pw"+i+j]=this.add.sprite((xRows[counter]+124), (yRows[counter]-306), "objectpack",state["p"+i+j]+"w");
					counter=counter+1;
					if(!state.lightson) {
						this["pw"+i+j].setAlpha(0);
					}
				}
			}





/*
			Row 1		x863y435	x916y436	x968y437	x1016y438	x1069y439
Row2		x857y486	x910y487	x962y488	x1010y489	x1063y490
Row3		x850y537	x903y538	x955y539	x1003y540	x1056y541
Row4		x844y588	x897y589	x949y590	x997y591	x1050y592
Row5		x837y639	x890y940	x942y641	x990y642	x1043y643
*/

			this.lightsstateoncreate=state.lightson;
			newState=true;
    }

		update() {
			if(newState) {
var counter
				for(var i=0; i<5; i++){
					for(var j=0; j<5; j++) {
						this["pw"+i+j].setTexture("objectpack",state["p"+i+j]+"w");
						counter=counter+1;
						if(!state.lightson) {
							this["pw"+i+j].setAlpha(0);
						}
					}
				}

				if(!this.lightsstateoncreate && state.lightson) {
							this.scene.start("Scene3i");
							this.lightsstateoncreate=false;
				}

				if(state.droppedpipe==false) {
					this["pw02"].setAlpha(0);
				}
				if(!state.lightson) {
					this.darkroom.setAlpha(1);
				} else {
					this.tonotices.setAlpha(0.01);
					this.tosarc.setAlpha(0.01);
					this.toorgan.setAlpha(0.01);
					this.topipes.setAlpha(0.01);
					this.totable.setAlpha(0.01);
				}
				newState=false;
			}

		}
}
