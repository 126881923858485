import Phaser from 'phaser'

export default class Scene3i extends Phaser.Scene
{
	constructor()
	{
		super({key: "Scene3i"});
	}

    create()
    {
			this.cutscene=this.add.video(960, 540, "baselightson");
			var sceneref=this;
			this.cutscene.on('complete', function(video){
							sceneref.scene.start("Scene3");
						});
			newState=true;
    }

		update() {
			if(newState) {
				this.cutscene.play();
				newState=false;
			}
		}
}
